import { useEffect, useState, useRef } from "react";
import { auth } from "../../../firebase";
import { RecaptchaVerifier } from "firebase/auth";
import {
  phoneSignInSendSMS,
  phoneSignInConfirm,
  handlePhoneSignInError,
} from "../../../utils/auth";
import { checkIfUserExists, register } from "../../../api";

import Button from "../../Button";
import DialCodePickerModal from "../../DialCodePicker";
import Modal from "../../Modal";
import Box from "../../Box";
import FullscreenLoading from "../../FullScreenLoading";

import { ChevronDown, Check, LogOut } from "react-feather";

import HappyIcon from "../../../assets/icons/happy.svg";

import styles from "./LoginForm.module.scss";

interface IProps {
  tryLinkAccount: boolean;
  onLogin: () => void;
}

declare global {
  interface Window {
    recaptchaVerifier: any;
    verificationID: any;
  }
}

const LoginForm: React.FC<IProps> = (props) => {
  const { onLogin } = props;

  const [loading, setLoading] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [signInLoading, setSignInLoading] = useState(false);

  const [dialCode, setDialCode] = useState({ flag: "🇨🇾", dial_code: "+357" });
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const [userExists, setUserExists] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const OTPRef = useRef<HTMLInputElement>(null);
  const [OTP, setOTP] = useState("");
  const [OTPError, setOTPError] = useState("");
  const [codeResentShown, setCodeResentShown] = useState(false);
  const [OTPLoading, setOTPLoading] = useState(false);

  const [modalDialCodeShown, setModalDialCodeShown] = useState(false);
  const [modalNameShown, setModalNameShown] = useState(false);
  const [modalOTPShown, setModalOTPShown] = useState(false);

  const fullPhoneNumber = dialCode.dial_code + phoneNumber;

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user && !user.isAnonymous && !userExists) {
        setLoggedIn(true);
      } else {
        setLoggedIn(false);
      }
    });

    // getRedirectResult(auth).then(async (result) => {
    //     if (result && result.user) {
    //         const details = getAdditionalUserInfo(result)

    //         if (details?.isNewUser) {
    //             setUserExists(true)
    //             setLoading(true)
    //             const { first_name, last_name, email, id } = details.profile as any;

    //             if (first_name || email) {
    //                 const data = {
    //                     uid: auth.currentUser?.uid,
    //                     email: email,
    //                     un: first_name.toLowerCase().replace(/\s/g, '') + "_" + last_name.toLowerCase().replace(/\s/g, ''),
    //                     fn: first_name,
    //                     ln: last_name,
    //                     os: 'web',
    //                     other_private: {
    //                         facebookID: id
    //                     }
    //                 }

    //                 await register(data).catch(err => { console.log(err) })

    //                 setLoading(false)
    //             }
    //         }

    //         const routeParams = history.location?.state as { from?: string }

    //         if (routeParams?.from) {
    //             history.push(routeParams.from)
    //         }
    //     }
    // }).catch(err => {
    //     handleFacebookError(err)
    // })

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (!loggedIn) {
      try {
        window.recaptchaVerifier = new RecaptchaVerifier(
          "sign-in-button",
          {
            size: "invisible",
            callback: (response) => {
              console.log(response);
            },
            "expired-callback": (err) => {
              console.log(err);
            },
          },
          auth
        );
      } catch (err) {
        console.error(err);
      }
    }
  }, [loggedIn]);

  const validatePhoneNumber = () => {
    if (!phoneNumber) {
      return false;
    }

    if (!/^\d+$/.test(phoneNumber)) {
      return false;
    }

    if (phoneNumber.length < 3) {
      return false;
    }

    return true;
  };

  const signIn = async () => {
    setPhoneError("");

    if (!validatePhoneNumber()) {
      setPhoneError("Please enter a valid number");
      return;
    }

    setSignInLoading(true);

    const phoneNum = fullPhoneNumber;

    try {
      const userExists = await checkIfUserExists({ phoneNumber: phoneNum });

      setUserExists(userExists ? true : false);
      if (userExists) {
        setFirstName(userExists.firstname);
      }

      if (userExists) {
        attemptSendSMS(phoneNum);
      } else {
        setModalNameShown(true);
      }
    } catch (e) {
      alert(
        (e as Error)?.message ||
          "Something went wrong, are you connected to the internet?"
      );
    }
  };

  const attemptSendSMS = async (phoneNumber: string) => {
    try {
      OTPRef.current!.value = "";
      setOTPError("");

      const verificationID = await phoneSignInSendSMS(phoneNumber);

      window.verificationID = verificationID;

      setModalOTPShown(true);
    } catch (e) {
      alert(
        (e as Error)?.message ||
          "Something went wrong, are you connected to the internet?"
      );
    }
  };

  const confirmOTP = async () => {
    if (!OTP || OTP.length !== 6) {
      setOTPError("Please enter a valid code");
      return;
    }

    setOTPLoading(true);

    await phoneSignInConfirm(OTP, props.tryLinkAccount)
      .then(async () => {
        setModalOTPShown(false);

        if (!userExists) {
          setLoading(true);
          const data = {
            uid: auth.currentUser?.uid,
            phoneNumber: fullPhoneNumber,
            un:
              firstName.toLowerCase().replace(/\s/g, "") +
              "_" +
              lastName.toLowerCase().replace(/\s/g, ""),
            fn: firstName,
            ln: lastName,
            os: "web",
          };

          await register(data);

          setLoading(false);
        }

        onLogin();

        setSignInLoading(false);
      })
      .catch(async (err: any) => {
        if (err.code === "auth/invalid-verification-code") {
          setOTPError("Code invalid. Try again or resend the code");
          try {
            OTPRef.current!.value = "";
          } catch (err) {}
        } else {
          await handlePhoneSignInError(err);
        }
      });

    setOTPLoading(false);
  };

  if (loggedIn) {
    return (
      <div
        className={styles.Container}
        style={{
          backgroundColor: "#f2f2f2",
          alignItems: "center",
          paddingTop: 30,
          boxSizing: "border-box",
        }}
      >
        <Box>
          <img src={HappyIcon} alt="happy icon" />

          <h3>You're logged in!</h3>

          <p style={{ marginTop: 10, marginBottom: 24 }}>
            Thank you for registering! Your card information will now be saved
            for future transactions :)
          </p>

          <Button
            fullWidth
            onClick={() => {
              auth.signOut();
            }}
          >
            <LogOut size={20} style={{ marginRight: 10 }} />
            Sign Out
          </Button>
        </Box>
      </div>
    );
  }

  return (
    <div className={styles.Container}>
      <form className={styles.MainContainer}>
        <h2 style={{ color: "#FF5722" }}>Let's get started!</h2>
        <p style={{ marginTop: 4, color: "#808080", fontSize: 14 }}>
          Enter your number below
        </p>
        <div style={{ display: "flex", gap: 10, width: "100%", marginTop: 22 }}>
          <div
            className={styles.GrayContainer + " touchable"}
            onClick={() => setModalDialCodeShown(true)}
          >
            <span style={{ fontSize: 28 }}>{dialCode.flag}</span>
            <ChevronDown size={20} style={{ margin: "0px 5px" }} />
            {dialCode.dial_code}
          </div>

          <div
            className={`${styles.GrayContainer} ${
              phoneError ? styles.error : ""
            }`}
            style={{ flex: 1 }}
          >
            <input
              className={styles.TextInput}
              disabled={signInLoading}
              placeholder="Phone Number"
              id="phone"
              name="phone"
              type="tel"
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            <span className={styles.ErrorText}>{phoneError}</span>
          </div>
        </div>

        <Button
          id="sign-in-button"
          type="submit"
          style={{
            boxShadow: "#00000029 0px 3px 6px",
            width: "100%",
            marginTop: 36,
          }}
          loading={signInLoading}
          onClick={(e) => {
            e.preventDefault();
            signIn();
          }}
        >
          SIGN IN
        </Button>

        {/* <div
                style={{
                    width: '100%',
                    marginTop: 20,
                    marginBottom: 20,
                    display: 'flex',
                    alignItems: 'center',
                    color: '#808080',
                    gap: '5%'
                }}
            >
                <div className={styles.Divider} />
                OR
                <div className={styles.Divider} />
            </div>

            <Button
                color='#3B5998'
                style={{ width: '100%' }}
                onClick={(e) => {
                    e.preventDefault();
                    facebookSignIn();
                }}
            >
                <Facebook style={{ marginRight: 10 }} />
                Sign in with Facebook
            </Button> */}
      </form>
      <div className={styles.muted + " " + styles.privacyNote}>
        If you are creating a new account,{" "}
        <a
          href="https://sally.app/media/docs/termsandconditions.html"
          target="_blank"
          rel="noreferrer"
        >
          Terms & Conditions
        </a>{" "}
        and{" "}
        <a
          href="https://sally.app/media/docs/privacypolicy.html"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </a>{" "}
        will apply.
      </div>

      <DialCodePickerModal
        modalStyle={{
          width: "100%",
        }}
        visible={modalDialCodeShown}
        setVisible={setModalDialCodeShown}
        select={(code) => {
          setDialCode(code);
          setModalDialCodeShown(false);
        }}
      />

      <Modal
        style={{
          width: "90%",
        }}
        active={modalNameShown}
        setActive={setModalNameShown}
        disableCloseOnBackClick
      >
        <div className={styles.ModalContainer}>
          <h2 style={{ color: "#FF5722" }}>
            Let's get to know
            <br />
            each other!
          </h2>
          <p style={{ marginTop: 4, color: "#808080", fontSize: 14 }}>
            Let us know your name
          </p>

          <div
            className={styles.GrayContainer}
            style={{
              flex: 1,
              width: "100%",
              paddingTop: 10,
              paddingBottom: 10,
              marginTop: 25,
            }}
          >
            <input
              className={styles.TextInput}
              placeholder="First Name"
              id="firstname"
              name="firstname"
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>

          <div
            className={styles.GrayContainer}
            style={{
              flex: 1,
              width: "100%",
              paddingTop: 10,
              paddingBottom: 10,
              marginTop: 20,
            }}
          >
            <input
              className={styles.TextInput}
              placeholder="Last Name"
              id="lastname"
              name="lastname"
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>

          <Button
            style={{ width: "100%", marginTop: 50 }}
            onClick={() => {
              setModalNameShown(false);
              attemptSendSMS(fullPhoneNumber).catch((error) => {
                setSignInLoading(false);
                alert(
                  "We couldn't send an SMS to your phone. Please refresh the page and try again. " +
                    error.message
                );
              });
            }}
          >
            NEXT
          </Button>
        </div>
      </Modal>

      <Modal
        style={{
          width: "90%",
        }}
        active={modalOTPShown}
        setActive={setModalOTPShown}
        onClose={() => {
          setSignInLoading(false);
        }}
      >
        <div className={styles.ModalContainer}>
          <h3 style={{ marginBottom: 10 }}>
            Welcome {userExists ? "Back" : ""}{" "}
            {firstName !== "user" ? firstName : ""}!
          </h3>
          <p>An SMS code was sent to</p>
          <p style={{ fontWeight: 600 }}>{fullPhoneNumber}</p>
          <p className={styles.Subtitle} style={{ color: "#FF5722" }}>
            Please enter the 6 digit code below
          </p>

          <div
            className={`${styles.GrayContainer} ${
              OTPError ? styles.error : ""
            }`}
            style={{
              flex: 1,
              width: "100%",
              paddingTop: 10,
              paddingBottom: 10,
              marginTop: 24,
            }}
          >
            <input
              ref={OTPRef}
              id="otp"
              name="otp"
              type="number"
              min="0.01"
              className={styles.TextInput}
              placeholder=""
              onChange={(e) => setOTP(e.target.value)}
            />

            <span className={styles.ErrorText}>{OTPError}</span>
          </div>

          {!codeResentShown ? (
            <p
              className={"touchable"}
              style={{
                fontSize: 14,
                color: "#FF5722",
                textDecoration: "underline",
                marginTop: 15,
              }}
              onClick={() => {
                attemptSendSMS(fullPhoneNumber)
                  .then(() => {
                    setCodeResentShown(true);
                    setTimeout(() => {
                      setCodeResentShown(false);
                    }, 10000);
                  })
                  .catch((err) => {
                    alert("Could not resend the code. Details: " + err.message);
                  });
              }}
            >
              Resend Code
            </p>
          ) : (
            <p
              style={{
                fontSize: 14,
                display: "flex",
                alignItems: "center",
                marginTop: 15,
              }}
            >
              <Check color="#2BBF00" style={{ marginRight: 8 }} />
              Code Resent
            </p>
          )}

          <Button
            style={{ width: "100%", marginTop: 24 }}
            loading={OTPLoading}
            onClick={confirmOTP}
          >
            {userExists ? "SIGN IN" : "REGISTER"}
          </Button>
        </div>
      </Modal>

      {loading && <FullscreenLoading />}
    </div>
  );
};

export default LoginForm;
