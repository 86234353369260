interface IProps {
    color?: string
    text: string
}

const Tag: React.FC<IProps> = props => {

    return (
        <div style={{
            backgroundColor: props.color || "#ed4d1c",
            paddingLeft: 7,
            paddingRight: 7,
            paddingTop: 3,
            paddingBottom: 3,
            borderRadius: 25,
            justifyContent: 'center', 
            alignItems: 'center',
            color: "#fff",
            fontSize: 12,
            fontWeight: "bolder"
        }}>
            {props.text}
        </div>
    )
}

export default Tag;
