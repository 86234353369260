import React from 'react';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

import DefaultModal from '../../Modal';

import { ISessionData } from '../../../contexts/GlobalContext';
import SectionCard from './SectionCard';

interface IProps {
    active: boolean
    sections: ISessionData["sections"]
    setActive: React.Dispatch<React.SetStateAction<boolean>>
    setSection: (name: string) => void
}

const SectionsModal: React.FC<IProps> = props => {
    const { active, sections, setActive, setSection } = props

    const { height } = useWindowDimensions()

    return <DefaultModal
        active={active}
        setActive={setActive}
        reducePadding
    >
        <div
            style={{
                width: "90%",
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                overflow: 'auto',
                height: height * 0.8
            }}
        >
            {sections && sections.map((section, index) => {
                if (!section.imageURL) {
                    return null
                }

                return <SectionCard
                    key={`sectioncard_${section.name}_$${index}`}
                    {...section}
                    onSelect={(name) => {
                        setActive(false)
                        setSection(name)
                    }}
                />
            })}
        </div>
    </DefaultModal>
}

export default SectionsModal;
