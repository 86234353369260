import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { translateMenu } from "../../../api";

import CategoryImages from "../CategoryImages";
import Category from '../Category';
import Position from '../Position';

import { useGlobalState } from "../../../contexts/GlobalContext";
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SectionsModal from "../../../Components/Menu/SectionsModal";
import LanguageModal from "./ModalLanguages";
import FullscreenLoading from '../../../Components/FullScreenLoading';
import ItemModal from "../../../Components/Menu/ItemModal";
import Modal from "../../../Components/Modal";
import CartButton from "../../../Components/Menu/CartButton";
import { Search, Globe, Check } from 'react-feather';

import styles from "../Menu.module.scss";

import { ICategory, IItem } from "../../../types/Menu";

interface IProps {

}

let timerInt: any;
let animeTimer: any;

const MenuScreen: React.FC<IProps> = () => {
    const navigate = useNavigate()

    const { data: sessionData, menuLanguage, cart, updateCart } = useGlobalState()
    const { menu: menuData, menuLanguages, name, currency, order, hideOutOfStock, style, sections } = sessionData!;

    const { section } = useParams<{ section: string }>();
    const refSpan = useRef<HTMLDivElement>(null);
    const refParent = useRef<HTMLDivElement>(null);
    const mainRef = useRef<HTMLDivElement>(null);
    const [activeLink, setActiveLink] = useState("");
    const [searchKey, setSearchKey] = useState('');
    const [isSearchActive, setIsSearchActive] = useState(false);
    const [menu, setMenu] = useState(menuData)
    const [selectedItem, setSelectedItem] = useState<IItem>()

    const [modalSectionsShown, setModalSectionsShown] = useState(!!sections)
    const [modalLanguageShown, setModalLanguageShown] = useState(false)
    const [modalAddedShown, setModalAddedShown] = useState(false)

    const [loading, setLoading] = useState(false);

    const currentMenuLanguageCode = React.useRef(menuLanguage)

    const sectionNames = sections ? sections.map(section => section.name) : Object.keys(menu)

    useEffect(() => {
        if (timerInt) {
            clearTimeout(timerInt);
        }
        timerInt = setTimeout(() => {
            refParent.current!.scrollTo(
                (document.getElementById(activeLink)?.offsetLeft ?? 0)
                - refParent.current!.offsetWidth
                + (document.getElementById(activeLink)?.offsetWidth ?? 0)
                + 200,
                0);
        }, 100);
    }, [activeLink]);

    const handleUnderlineAnimation = (elId: string) => {
        if (animeTimer) {
            clearTimeout(animeTimer);
        }
        animeTimer = setTimeout(() => {
            if (!refParent.current) {
                return;
            }
            setActiveLink(elId);
            const el = document.getElementById(elId);
            const { width = 0, x = 0 } = el?.getBoundingClientRect() ?? {};
            const parentCoords = refParent.current!.getBoundingClientRect();
            refSpan.current!.style.width = `${width}px`;
            refSpan.current!.style.borderBottom = `4px solid ${style?.primary || "#f4511e"}`;
            refSpan.current!.style.transform = `translateX(${(x - parentCoords.left) + refParent.current.scrollLeft}px`;
        }, 100);
    }

    const handleSearchInput = (e) => {
        e.target.value.length >= 2 ? setSearchKey(e.target.value) : setSearchKey('');
    }

    const translate = async (language) => {
        setModalLanguageShown(false);

        if (currentMenuLanguageCode.current === language.code) {
            return;
        }

        setLoading(true)

        try {
            const res = await translateMenu({
                source: "en",
                target: language.code,
                v_id: sessionData?.venue_id
            })

            if (res.menu) {
                currentMenuLanguageCode.current = language.code
                setMenu(res.menu)
            }
        } catch (err) {
            const error = err as Error

            alert("An unexpected error has occured. Details: " + error.message)
        } finally {
            setLoading(false)
        }
    }

    if (!section) {
        return null
    }

    let dataMenu: ICategory[];
    if (!searchKey) {
        dataMenu = menu[section] ? menu[section] : menu[Object.keys(menu)[0]];
    }
    else {
        dataMenu = Object.keys(menu)
            .map(sectionKey => menu[sectionKey])
            .reduce((prev, next) => [...prev, ...next], [])
            .filter(section => {
                const reg = new RegExp(escapeRegExp(searchKey), "ig");
                return section.itemsarray.some(item =>
                    reg.test(item.translatedTitle || item.title)
                    || reg.test(item.translatedDesc || item.desc)
                );
            });
    }

    return (
        <>
            <div className={styles.Menu}>
                <header className={styles.Header}>
                    <div className={styles.ElNav}>
                        <IconButton component={Link} to='../'
                            aria-label="delete"
                        >
                            <ArrowBackIcon />
                        </IconButton>
                        <h1 className={styles.Title}>
                            {name}
                        </h1>
                        <div
                            style={{
                                display: "flex"
                            }}
                        >
                            <Globe className={styles.GlobeIcon} onClick={() => setModalLanguageShown(true)} />
                            <Search onClick={() => setIsSearchActive(!isSearchActive)} className={styles.SearchIcon} />
                        </div>
                    </div>

                    <label className={isSearchActive ? styles.SearchLabel + ' ' + styles.Active : styles.SearchLabel}>
                        <input
                            onChange={e => handleSearchInput(e)}
                            className={styles.SearchInput}
                            type="text"
                            placeholder="Search..."
                        />
                        <Search className={styles.ImgSearch} />
                    </label>

                    {<div className={isSearchActive ? styles.WrapperDisabled : styles.ElNavBtnBox}>
                        <div className={styles.ScrollMenu}>
                            {sectionNames.map((sectionName, index) => {
                                return (
                                    <NavLink
                                        key={"section" + index}
                                        to={'../menu/' + encodeURI(sectionName)}
                                        className={({ isActive }) =>
                                            `${styles.ElNavBtn} ${isActive ? styles.ElNavBtnActive : ''}`
                                        }
                                        onClick={() => {
                                            window.scrollTo(0, 0);
                                            handleUnderlineAnimation("");
                                        }}
                                    >
                                        {sectionName === "KidsMenu" ? sectionName.replace('s', 's ') : sectionName}
                                    </NavLink>
                                )
                            })}
                        </div>
                    </div>}

                    <div className={isSearchActive ? styles.WrapperDisabled : styles.Wrapper}>
                        <div ref={refParent} className={styles.ScrollMenu}>
                            {dataMenu.map((item, index) => {
                                return <Category
                                    key={"category" + index}
                                    itemIndex={index}
                                    {...item}
                                    hideOutOfStock={hideOutOfStock}
                                    className={styles.linkSubMenu}
                                    activeClassName={styles.linkSubMenuActive}
                                    underlineAnimation={handleUnderlineAnimation}
                                />
                            })}
                            <span ref={refSpan} className={styles.Highlight} />
                        </div>
                    </div>
                </header>

                <main className={styles.Main} ref={mainRef}>
                    {!searchKey && !isSearchActive ?
                        <CategoryImages
                            categories={dataMenu}
                        />
                        : null}

                    {dataMenu.map((category, index) => {
                        return <Position
                            {...category}
                            key={`${category.catTitle}${index}`}
                            index={index}
                            searchKey={searchKey}
                            currency={currency}
                            hideOutOfStock={hideOutOfStock}
                            addScrollListener={(func) => {
                                mainRef.current?.addEventListener("scroll", func)
                            }}
                            removeScrollListener={(func) => {
                                mainRef.current?.removeEventListener("scroll", func)
                            }}
                            onChange={handleUnderlineAnimation}
                            onClickItem={order &&
                                ((item: IItem) => {
                                    setSelectedItem(item)
                                })
                            }
                        />
                    })}

                    {selectedItem ?
                        <ItemModal
                            type="new"
                            item={selectedItem}
                            section={section}
                            currency={currency}
                            addToCart={(cartItem) => {
                                updateCart("ADD", cartItem)

                                setSelectedItem(undefined)
                                setModalAddedShown(true)
                                setTimeout(() => {
                                    setModalAddedShown(false)
                                }, 900)
                            }}
                            onClose={() => {
                                setSelectedItem(undefined)
                            }}
                        />
                        : null}

                </main>

                {order ?
                    <CartButton
                        num={cart.length}
                        onClick={() => {
                            if (cart.length > 0) {
                                navigate('../order', { state: { from: window.location.pathname } })
                            } else {
                                alert("Your cart is empty, please add an item first :)")
                            }
                        }}
                    />
                    : null}

                <SectionsModal
                    active={modalSectionsShown}
                    sections={sections}
                    setActive={setModalSectionsShown}
                    setSection={(name) => {
                        navigate('../menu/' + name)
                        window.scrollTo(0, 0);
                    }}
                />

                <Modal
                    style={{
                        height: "25vh",
                        width: "25vh",
                        borderRadius: "50%",
                        minHeight: "unset",
                        justifyContent: "center"
                    }}
                    containerStyle={{
                        backdropFilter: "unset"
                    }}
                    active={modalAddedShown}
                    setActive={setModalAddedShown}
                >
                    <Check color="#2BBF00" size={100} />
                    <h4>ADDED!</h4>
                </Modal>

                <LanguageModal
                    active={modalLanguageShown}
                    topLanguages={menuLanguages}
                    setActive={setModalLanguageShown}
                    translate={translate}
                />

                {loading ? <FullscreenLoading /> : null}
            </div>
        </>
    );
}

function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export default MenuScreen;