import { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router';
import { useGlobalState } from '../../contexts/GlobalContext';
import { randomIntFromInterval } from '../../utils/common';

import { onValue, off } from 'firebase/database';
import { auth, dbRef, getChildRef } from '../../firebase';
import { signInAnonymously } from 'firebase/auth';
import { attemptPayment, getCheck } from '../../api';

import CircularLoader from '../../Components/CircularLoader';
import { CircularProgress } from '@material-ui/core';

import SelectItems from './SelectItems';
import OverpaymentModal from './ModalOverpayment'
import TipsModal from './ModalTips';
import LoginModal from '../../Components/auth/LoginModal';
import PercentageBox from './ModalTips/PercentageBox';
import BottomSheet from '../../Components/BottomSheet';
import Button from '../../Components/Button';
import Box from '../../Components/Box';

import { Edit, ArrowRight, RefreshCw } from 'react-feather';
import { Apple } from '@material-ui/icons';
import GoogleIcon from '../../assets/icons/pay/google.svg';
import NoExpressionIcon from '../../assets/icons/noexpression.svg';
import CryIcon from '../../assets/icons/cry.svg'
import SephoraImg from '../../assets/img/sephora-min.png';
import percentages from '../../assets/percentages.json';

import styles from './Pay.module.scss';

import { IExPayment } from './SelectItems';
import SelectPaymentMethod from '../../Components/SelectPaymentMethod';

const PayScreen: React.FC = () => {
    const { code } = useParams<{ code: string }>()
    const { data } = useGlobalState()
    const { name: venueName, logoURL, currency, venue_id, table_id } = data!

    const [loading, setLoading] = useState(true)
    const [paymentLoading, setPaymentLoading] = useState(false)
    const [modalSelectShown, setModalSelectShown] = useState(true)
    const [modalOverpaymentShown, setModalOverpaymentShown] = useState(false)
    const [modalTipsShown, setModalTipsShown] = useState(false)
    const [modalLoginShown, setModalLoginShown] = useState(false)

    const [sheetTipShown, setSheetTipShown] = useState(false)

    const [total, setTotal] = useState<number>(0)
    const [checkTotal, setCheckTotal] = useState<number>(0)
    const [balance, setBalance] = useState<number>(0)
    const [tips, setTips] = useState<number>(0)
    const [items, setItems] = useState([])

    const [exPayments, setExPayments] = useState<{ [id: string]: IExPayment }>({})
    const tableRef = useRef('')

    const [error, setError] = useState('')

    const [selectedPM, setSelectedPM] = useState<'new_card' | 'apple' | 'google' | string | null>('new_card')
    const selectedPMIsCard = selectedPM && selectedPM !== 'apple' && selectedPM !== 'google'

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async user => {
            if (!user) {
                await signInAnonymously(auth);
            } else {
                init();
            }
        })

        return () => {
            unsubscribe()
            if (tableRef.current) {
                const dbTableRef = dbRef(tableRef.current!)
                off(dbTableRef, "value")
            }
        }
    }, [])

    const init = async () => {
        setError('');

        if (!code) {
            return
        }

        try {
            const {
                total,
                balance,
                items,
                tableRef: tableRefStr
            } = await getCheck({ code: code })
                .catch(err => {
                    setError(err.message)
                })

            if (total && total !== 0) {
                tableRef.current = tableRefStr
                listenToExPayments(tableRefStr)

                setTotal(balance || total)
                setCheckTotal(total)
                setBalance(balance)
                setItems(items)
            }
        } catch (err: any) {
            setError(err.message)
        } finally {
            setLoading(false)
        }
    }

    const listenToExPayments = async (ref: string) => {
        const dbTableRef = dbRef(ref)

        const paymentsRef = getChildRef("payments", dbTableRef)

        onValue(paymentsRef, (snapshot) => {
            const val: { [id: string]: IExPayment } = snapshot.val() || []
            setExPayments(val)
        })
    }

    const pay = async () => {
        setPaymentLoading(true)

        let currentURL = window.location.origin + window.location.pathname

        const successURL = currentURL + `/success?gif=${randomIntFromInterval(1, 11)}`
        const failURL = currentURL + '?paymentFailed=true'

        const data = {
            v2: true,
            card_id: selectedPMIsCard ? selectedPM : null,
            v_id: venue_id,
            t_id: table_id,
            amount: total,
            tips: tips,
            total: total + tips,
            successReturnURL: successURL,
            failReturnURL: failURL
        }

        const res = await attemptPayment(data)
            .catch((err: any) => {
                setPaymentLoading(false)
                alert('An unexpected error has occured')
            })

        if (res?.link) {
            window.location = res.link
        } else {
            throw new Error('function error')
        }
    }

    if (loading) {
        return <div style={{ width: '80%', alignSelf: 'center', textAlign: 'center' }}>
            <CircularLoader />
        </div>
    }

    if (error) {
        return <div className={styles.Container} style={{ paddingTop: 30, backgroundColor: '#f2f2f2' }}>
            <Box style={{ alignSelf: 'center' }}>
                <img src={CryIcon} alt='no expression face' style={{ paddingTop: 10, paddingBottom: 10 }} />

                <h3>Oops!</h3>
                <p style={{ fontSize: 14 }}>The Point of Sale of the restaurant is not available. Could you reach out to your waiter?</p>

                <Button
                    style={{ width: '100%', marginTop: 15 }}
                    onClick={() => {
                        setLoading(true)
                        init()
                    }}
                >
                    <RefreshCw size={20} style={{ marginRight: 8 }} />
                    Try Again
                </Button>
            </Box>
        </div>
    }

    if (checkTotal === 0) {
        return <div className={styles.Container} style={{ paddingTop: 30, backgroundColor: '#f2f2f2' }}>
            <Box style={{ alignSelf: 'center' }}>
                <img src={NoExpressionIcon} alt='no expression face' />

                <h3>No orders yet</h3>
                <p style={{ fontSize: 14 }}>We could not find any orders on your table. If you believe this is a mistake, please contact your waiter</p>

                <Button
                    style={{ width: '100%', marginTop: 15 }}
                    onClick={() => {
                        setLoading(true)
                        init()
                    }}
                >
                    <RefreshCw size={20} style={{ marginRight: 8 }} />
                    Try Again
                </Button>
            </Box>
        </div>
    }

    const paymentTotal = total + tips;

    return <>
        <SelectItems
            visible={modalSelectShown}
            items={items}
            exPayments={exPayments}
            currency={currency}
            total={total}
            checkTotal={checkTotal}
            balance={balance}
            onChange={(total) => {
                setTotal(total)
            }}
            onClose={() => {
                setModalSelectShown(false)

                const left = balance ? balance : checkTotal

                if (total > left) {
                    setModalOverpaymentShown(true)
                } else {
                    setModalTipsShown(true)
                }
            }}
        />

        {!modalSelectShown ? <div className={styles.Container}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px 0', fontSize: 16 }}>
                <img src={logoURL} alt='venue logo' className={styles.Logo} />

                <h3>{venueName}</h3>

                {table_id && <div>Table {table_id}</div>}

                <div style={{ width: '90%', marginTop: 14 }}>
                    <h4 style={{ marginBottom: 20 }}>Payment Total</h4>
                    <div className={styles.Row}>
                        <div style={{ flex: 1 }}>
                            Subtotal (including tax)
                        </div>
                        <div>
                            {currency}{total.toFixed(2)}
                        </div>
                    </div>
                    <div className={`${styles.Row} touchable`} onClick={() => setModalTipsShown(true)}>
                        <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                            Tips
                            <Edit size={18} color='#808080' strokeWidth={3} style={{ marginLeft: 10 }} />
                        </div>
                        <div>
                            {currency}{tips.toFixed(2)}
                        </div>
                    </div>

                    <div className={styles.Row} style={{ fontWeight: 600 }}>
                        <div style={{ flex: 1 }}>
                            Total
                        </div>
                        <div>
                            {currency}{paymentTotal.toFixed(2)}
                        </div>
                    </div>

                    <div className={styles.PaymentMethodContainer}>
                        <h4 style={{ marginBottom: 20 }}>Select Payment Method</h4>

                        {/* <div className={`${styles.PaymentMethod} ${selectedPM === 'apple' ? styles.selected : ''}`} onClick={() => setSelectedPM('apple')}>
                        <Apple style={{ marginRight: 10 }} />
                        <div style={{ flex: 1 }}>
                            Apple Pay
                        </div>

                        {selectedPM === 'apple' && <CheckCircle color='#2BBF00' />}
                    </div>

                    <div className={`${styles.PaymentMethod} ${selectedPM === 'google' ? styles.selected : ''}`} onClick={() => setSelectedPM('google')}>
                        <img src={GoogleIcon} alt='google logo' style={{ marginRight: 10, height: 22 }} />
                        <div style={{ flex: 1 }}>
                            Google Pay
                        </div>

                        {selectedPM === 'google' && <CheckCircle color='#2BBF00' />}
                    </div> */}

                        <SelectPaymentMethod
                            venue_id={venue_id}
                            paymentsDisabled={false}
                            selected={selectedPM}
                            setSelected={(pm) => {
                                setSelectedPM(pm)
                            }}
                            loading={paymentLoading}
                            onClickLogin={() => {
                                setModalLoginShown(true)
                            }}
                        />

                        <Button
                            color={selectedPMIsCard ? 2 : '#000'}
                            style={{ marginTop: 25 }}
                            disabled={!selectedPM || paymentLoading}
                            onClick={() => {
                                if (!tips) {
                                    setSheetTipShown(true)
                                } else {
                                    // pay
                                    pay()
                                }
                            }}
                        >
                            {!paymentLoading ? <>
                                {selectedPM === 'apple' && <Apple style={{ fontSize: 30, marginRight: 10 }} />}
                                {selectedPM === 'google' && <img src={GoogleIcon} alt='google logo' style={{ height: 30, marginRight: 10 }} />}
                                {selectedPMIsCard ? 'PAY WITH CARD' : selectedPM === 'apple' ? 'Apple Pay' : selectedPM === 'google' ? 'Google Pay' : 'PAY'}

                            </> : <CircularProgress style={{ color: 'inherit', height: 30, width: 30, marginLeft: '46.5%' }} />}
                            <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 8 }}>
                                {currency}{paymentTotal.toFixed(2)}
                                <ArrowRight />
                            </div>
                        </Button>
                    </div>
                </div>
            </div>
        </div> : null}

        <OverpaymentModal
            visible={modalOverpaymentShown}
            setActive={setModalOverpaymentShown}
            showBill={() => {
                setModalOverpaymentShown(false)
                setModalSelectShown(true)
            }}
            addAsTip={(total: number) => {
                setModalOverpaymentShown(false)
                setTips(total)
                setTotal(balance || checkTotal)
            }}
            payOnly={(total: number) => {
                setTotal(total)
                setModalOverpaymentShown(false)
                setModalTipsShown(false)
            }}
            currency={currency}
            total={total - (balance ? balance : checkTotal)}
            balance={balance || checkTotal}
        />

        <TipsModal
            visible={modalTipsShown && !modalSelectShown && !modalOverpaymentShown}
            setActive={setModalTipsShown}
            currency={currency}
            total={total}
            tips={tips}
            onChange={(num) => {
                setTips(num)
            }}
        />

        <LoginModal
            tryLinkAccount={false}
            visible={modalLoginShown}
            setVisible={setModalLoginShown}
            onLogin={() => {
                setModalLoginShown(false)
            }}
        />

        <BottomSheet active={sheetTipShown} setActive={setSheetTipShown}>
            <img src={SephoraImg} alt='cute 3 legged cat called sephora' style={{ position: 'absolute', height: 100, top: -72, right: 12 }} />
            <div style={{ display: 'flex', flexDirection: 'column', padding: '0 20px' }}>
                <h1 style={{ padding: '8px 0' }}>What about a tip?</h1>
                <div className={styles.muted}>tip to show your support</div>

                {percentages.map(el => (
                    <PercentageBox
                        key={'sheet_pbox' + el.value}
                        style={{ marginBottom: 8 }}
                        {...el}
                        onClick={(val) => {
                            const num = total * val
                            setTips(Math.round((num + Number.EPSILON) * 100) / 100)
                            setSheetTipShown(false)
                        }}
                    />
                ))}

                <Button
                    color={'#000'}
                    style={{ marginTop: 25 }}
                    onClick={() => {
                        setSheetTipShown(false)
                        pay()
                    }}>
                    no tips today
                </Button>
            </div>
        </BottomSheet>
    </>
}

export default PayScreen;
