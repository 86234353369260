import React, { useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";

import updateCartHandler, { UpdateCartActions } from "./handlers/cart";

import { Menu } from "../types/Menu";
import { Cart } from "../types/Order";
import { getVenueMenu } from "../api";

export interface ISessionData {
  venue_id: string;
  name: string;
  type: string;
  order: boolean;
  pay: boolean;
  selfService: boolean;
  paymentsDisabled: boolean;
  cashPayments: boolean;
  cashPaymentsCustomText?: string;
  requestServices?: ("waiter" | "bill" | "ice")[];
  currency: string;
  logoURL: string;
  coverURL: string;
  table_id: string;
  menu: Menu;
  menuLanguage: string;
  menuLanguages?: string[];
  defaultMenuSection?: string;
  hideOutOfStock?: boolean;
  tableLabel?: string;
  extraCharge?: {
    amount: number;
    reason: string;
  };
  authDisabled?: boolean;
  style?: {
    primary: string;
  };
  sections?: {
    name: string;
    val?: string;
    imageURL?: string;
  }[];
  promo?: {
    title: string;
    text: string;
    imageURL: string;
  };
}

export interface IGlobalContext {
  loading: boolean;
  data?: ISessionData;
  menuLanguage: string;
  cart: Cart;
  updateCart: (type: UpdateCartActions, payload: any) => void;
}

export const GlobalContext = React.createContext<IGlobalContext>({
  loading: true,
  menuLanguage: "en",
  cart: [],
  updateCart: () => {},
});

const cookieExpiryDate = new Date();
cookieExpiryDate.setTime(cookieExpiryDate.getTime() + 2 * 60 * 60 * 1000);

const GlobalContextWrapper: React.FC = (props) => {
  const [cookies, setCookies] = useCookies();

  const [cart, setCart] = useState<Cart>([]);
  const [sessionData, setSessionData] = useState<ISessionData>();
  const [menuLanguage, setMenuLanguage] = useState("en");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    function init() {
      let tempCart = cookies.cart;

      if (tempCart) {
        if (typeof tempCart === "string") {
          try {
            tempCart = JSON.parse(tempCart);
          } catch (err) {
            console.error(
              "Could not parse cart from cookies. Details: ",
              err,
              tempCart
            );
          }
        }

        if (Array.isArray(tempCart)) {
          setCart(tempCart);
        }
      }
    }

    async function fetchMenuData() {
      try {
        setLoading(true);

        const paths = window.location.pathname.split("/");
        const loaded = cookies.loaded === "true";

        const response = await getVenueMenu({
          code: paths[2],
          loaded: loaded,
        });

        setSessionData(response);
        setMenuLanguage(response?.menuLanguage || "en");

        setCookies("loaded", true, { expires: cookieExpiryDate });
        setCookies("table_code", paths[2]);

        if (response.style?.primary) {
          document.documentElement.style.setProperty(
            "--primary-color",
            response.style.primary
          );
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    }

    init();
    fetchMenuData();
  }, []);

  useEffect(() => {
    if (cart.length > 0) {
      setCookies("cart", JSON.stringify(cart), { expires: cookieExpiryDate });
    } else if (cookies.cart) {
      setCookies("cart", "");
    }
  }, [cart]);

  return (
    <GlobalContext.Provider
      value={{
        loading,
        data: sessionData,
        menuLanguage,
        cart: cart,
        updateCart: (type: UpdateCartActions, payload: any) => {
          const c = updateCartHandler(cart, type, payload);

          setCart(c);
        },
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};

export const useGlobalState = () => {
  return useContext<IGlobalContext>(GlobalContext);
};

export default GlobalContextWrapper;
