import React from 'react';
import { useLocation } from 'react-router';
import { useGlobalState } from "../contexts/GlobalContext";

import Header from './Header';
import Loader from "../Components/Loader";

import styles from './Layout.module.scss';
import { Outlet } from 'react-router-dom';

const screensWithHeader = ['pay', 'success', 'login', 'order', 'status']

const Layout: React.FC = (props: any) => {
    const { loading } = useGlobalState();
    const location = useLocation();

    const paths = location.pathname.split('/').filter(p => p)

    const showHeader = (screensWithHeader.indexOf(paths[paths.length - 1]) > -1) ||
                            (screensWithHeader.indexOf(paths[2]) > -1)

    return <div className={styles.Layout}>
        {showHeader && <Header back />}
        <main className={styles.Main}>
            {loading ? <Loader /> : <Outlet />}
        </main>
    </div>
}

export default Layout;