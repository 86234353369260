import DefaultModal from "../Modal";

import appleLink from "../../assets/img/AppleDownload.svg"
import androidLink from "../../assets/img/AndroidDownload.svg"
import graphic from "../../assets/img/graphic.svg"
import logo from "../../assets/img/logo.svg"

import styles from "./ModalLink.module.scss";

const ModalLink = ({ active, setActive }) => {
    return (
        <DefaultModal active={active} setActive={setActive}>
            <img src={graphic} alt="graphic" className={styles.Graphic1} />
            <img src={logo} alt="logo" className={styles.Logo} />
            <span className={styles.TextModal}>
                Send gifts, chat with your friends,
                meet new people and much more!
            </span>
            <div className={styles.BoxLink}>
                <a href="https://apps.apple.com/cy/app/sally-social-ordering/id1531501413">
                    <img src={appleLink} alt="appleLink" className={styles.LinkAndroid} />
                </a>
                <a href="https://play.google.com/store/apps/details?id=app.sally.app">
                    <img src={androidLink} alt="androidLink" className={styles.LinkAndroid} />
                </a>
            </div>
        </DefaultModal>
    )
}

export default ModalLink;
