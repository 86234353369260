import React from 'react';

import styles from './TextInput.module.scss';

interface IProps {
    onChange: (text: string) => void
    className?: string
    containerStyle?: React.CSSProperties
    style?: React.CSSProperties
    color?: 1 | 2 | 3
    fullWidth?: boolean
    placeholder?: string
    left?: React.FC
    right?: React.FC
    default?: string
    autofocus?: boolean
    type?: string
    min?: string
    maxLength?: number
    validate?: (text: string) => string
    validateOnBlur?: (text: string) => string
    clearOnBlur?: boolean
}

const isDefaultEqual = (prevProps:IProps, nextProps:IProps) => prevProps.default !== nextProps.default 

const TextInput: React.FC<IProps> = React.memo(props => {
    const [text, setText] = React.useState(props.default || '')
    const { color, fullWidth, placeholder } = props

    let skipChange = React.useRef(false)

    React.useEffect(() => {
        if (!skipChange.current) {
            props.onChange(text)
        } else {
            skipChange.current = false
        }
    }, [text])

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newText = props.validate ? props.validate(e.target.value) : e.target.value
        setText(newText)
    }

    const containerStyle = {
        ...props.containerStyle,
        width: fullWidth ? '90%' : undefined
    }

    return <div className={styles.Container} style={containerStyle}>
        {props.left && <props.left />}
        <input
            type={props.type}
            min={props.min}
            autoFocus={props.autofocus}
            className={`${styles.Input} ${props.className ? props.className : ""}`}
            onChange={onChange}
            value={text}
            maxLength={props.maxLength}
            placeholder={placeholder}
            onBlur={() => {
                const newText = props.validateOnBlur ? props.validateOnBlur(text) : null

                if (newText) {
                    setText(newText)
                }

                if (props.clearOnBlur) {
                    skipChange.current = true
                    setText('')
                }
            }}
        />
        {props.right && <props.right />}
    </div>
}, isDefaultEqual)

export default TextInput;