import React from 'react';
import { EmojiEmotions, Star } from '@material-ui/icons';
import Pepper from '../../assets/icons/menu/pepper-solid.svg';
import Wine from '../../assets/icons/menu/wine-glass-solid.svg';
import Signature from '../../assets/icons/menu/signature.svg';
import ChefHat from '../../assets/icons/menu/chef-hat.svg';

export default function Tags(props) {
    const { tags, catIndex, itemIndex } = props

    if (tags.length === 0) {
        return null
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 3 }}>
            {tags.map((tag, tagIndex) => {
                const key = `tag${tagIndex}_${catIndex}_${itemIndex}`
                switch (tag) {
                    case 'new':
                        return <Tag key={key} icon={tag} label='NEW' color='#FDCC0D' mc />
                    case 'popular':
                        return <Tag key={key} icon={tag} label='POPULAR' color='skyblue' />
                    case 'spicy':
                        return <Tag key={key} icon={tag} label='SPICY' color='red' fa5 />
                    case 'alcohol':
                        return <Tag key={key} icon={tag} label='ALCOHOL' color='#0e1111' mc />
                    case 'signature':
                        return <Tag key={key} icon={tag} label='SIGNATURE' color='purple' fa5 />
                    case 'chefs-choice':
                        return <Tag key={key} icon={tag} label={`CHEF'S CHOICE`} color='brown' mc />
                    default:
                        return null
                }
            })}
        </div>
    )
}

function Tag(props) {
    const { label, color, icon } = props

    const iconProps = {
        height: 16,
        width: 16
    }

    return (
        <div style={{ display: 'inline-block' }}>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                backgroundColor: color,
                borderRadius: 6,
                paddingRight: 6,
                paddingLeft: 6,
                paddingTop: 3,
                paddingBottom: 3,
                marginRight: 4,
                color: 'white',
                width: 'auto',
                height: 24
            }}>
                {icon === 'new' ? <EmojiEmotions {...iconProps} /> :
                icon === 'popular' ? <Star {...iconProps} /> :
                icon === 'spicy' ? <img src={Pepper} alt='pepper' {...iconProps} /> :
                icon ==='alcohol' ? <img src={Wine} alt='wine' {...iconProps} /> :
                icon === 'signature' ? <img src={Signature} alt='signature' {...iconProps} /> :
                icon === 'chefs-choice' ? <img src={ChefHat} alt='chef hat' {...iconProps} />: null}
                <span style={{ marginLeft: 3, color: 'white', fontSize: 12, fontWeight: 500 }}>{label}</span>
            </div>
        </div>
    )
}