const GIFLinks = [
    'https://firebasestorage.googleapis.com/v0/b/sally-1578747294813.appspot.com/o/media%2Fgifs%2Ffriends-1.gif?alt=media&token=ec4bd6ed-0ec2-4304-99dd-be5971702bee',
    'https://firebasestorage.googleapis.com/v0/b/sally-1578747294813.appspot.com/o/media%2Fgifs%2Ffriends-2.gif?alt=media&token=1baf28ad-1504-49fb-af1f-12e66a547707',
    'https://firebasestorage.googleapis.com/v0/b/sally-1578747294813.appspot.com/o/media%2Fgifs%2Ffriends-3.gif?alt=media&token=7d999d66-dfc2-448f-9f24-1bab00f63501',
    'https://firebasestorage.googleapis.com/v0/b/sally-1578747294813.appspot.com/o/media%2Fgifs%2Ffriends-4.gif?alt=media&token=ad222bf4-1fdb-4502-9cb4-2a1c64fb894a',
    'https://firebasestorage.googleapis.com/v0/b/sally-1578747294813.appspot.com/o/media%2Fgifs%2Ffriends-5.gif?alt=media&token=bdac13c0-ebc1-42ce-9193-05e22e371528',
    'https://firebasestorage.googleapis.com/v0/b/sally-1578747294813.appspot.com/o/media%2Fgifs%2Fhimym-1.gif?alt=media&token=918b4106-607b-4a3f-b45d-e0e49b3e6014',
    'https://firebasestorage.googleapis.com/v0/b/sally-1578747294813.appspot.com/o/media%2Fgifs%2Fhimym-2.gif?alt=media&token=0ffff951-d681-4410-944f-d20bbc1f1372',
    'https://firebasestorage.googleapis.com/v0/b/sally-1578747294813.appspot.com/o/media%2Fgifs%2Fbbg-1.gif?alt=media&token=cf2f8d71-1b7e-4ac2-9b16-29ffb2dcb6af',
    'https://firebasestorage.googleapis.com/v0/b/sally-1578747294813.appspot.com/o/media%2Fgifs%2Fhappy-1.gif?alt=media&token=de2bfab8-6c33-415c-867f-01562f5ed422',
    'https://firebasestorage.googleapis.com/v0/b/sally-1578747294813.appspot.com/o/media%2Fgifs%2Fhappy-2.gif?alt=media&token=979a38d8-5b3b-473a-a4df-fdef09fcbb5c',
    'https://firebasestorage.googleapis.com/v0/b/sally-1578747294813.appspot.com/o/media%2Fgifs%2Fhappy-3.gif?alt=media&token=40b6b8ed-ff24-4aa0-b5b2-980d2116f90a',
    'https://firebasestorage.googleapis.com/v0/b/sally-1578747294813.appspot.com/o/media%2Fgifs%2Fhappy-4.gif?alt=media&token=1dd320af-78fd-4d99-89c4-aa27a23f9537'
]

export default GIFLinks