import React from 'react';

import DefaultModal from '../../../Components/Modal';
import InputSpinner from '../../../Components/InputSpinner';
import TextInput from '../../../Components/TextInput';
import Button from '../../../Components/Button';

import PercentageBox from './PercentageBox';

import { Edit3 } from 'react-feather';

import CuteIcon from '../../../assets/icons/cute.svg';

import useWindowDimensions from '../../../hooks/useWindowDimensions';

import styles from './ModalTips.module.scss';

import percentages from '../../../assets/percentages.json';

const TipsModal = ({ visible, setActive, tips, currency, total, onChange }) => {

    const { width } = useWindowDimensions()

    const update = (num: number) => {
        onChange(Math.round((num + Number.EPSILON) * 100) / 100)
    }

    return <DefaultModal active={visible} setActive={setActive} reducePadding disableCloseOnBackClick x>
        <div style={{ width: width * 0.8, display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 10 }}>
            <img src={CuteIcon} style={{ height: 64, marginTop: 12 }} />
            <h4 style={{ marginTop: 12, marginBottom: 12 }}>Add Tip?</h4>

            <InputSpinner num={tips} step={0.5} onChange={update} />

            <div style={{ width: '90%', display: 'flex', alignItems: 'center', gap: 5, marginTop: 10 }}>
                {percentages.map((el) => {
                    return <PercentageBox key={'pbox' + el.value} {...el} onClick={(val) => update(total * val)} />
                })}
            </div>

            <TextInput
                type='number'
                min='0.01'
                fullWidth
                placeholder='Or add a custom tip'
                left={() => <div>{currency}</div>}
                right={() => <Edit3 color='#F4511E' />}
                containerStyle={{ marginTop: 10 }}
                onChange={(text) => { update(text ? parseFloat(text) : 0) }}
                clearOnBlur
            />

            <div className={styles.Container}>
                <div style={{ flex: 1 }}>Bill Total</div>

                <div>
                    {currency}{(total + tips).toFixed(2)}
                </div>
            </div>

            <Button
                color={2}
                fullWidth
                onClick={() => setActive(false)}
                style={{ marginTop: 24 }}
            >
                DONE
            </Button>
        </div>
    </DefaultModal>
}

export default TipsModal