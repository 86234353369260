import {
  FacebookAuthProvider,
  linkWithRedirect,
  signInWithRedirect,
  signInWithCredential,
  PhoneAuthProvider,
  linkWithCredential,
} from "firebase/auth";
import { auth } from "../firebase";

export const facebookSignIn = async () => {
  const facebookProvider = new FacebookAuthProvider();

  if (auth.currentUser?.isAnonymous) {
    return linkWithRedirect(auth.currentUser, facebookProvider);
  } else {
    return signInWithRedirect(auth, facebookProvider);
  }
};

export const handleFacebookError = async (err: any) => {
  if (
    err.code === "auth/credential-already-in-use" ||
    err.code === "auth/account-exists-with-different-credential"
  ) {
    const pendingCred = err.credential;
    auth
      .signOut()
      .then(() => {
        return signInWithCredential(auth, pendingCred);
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  } else {
    alert(err.message);
  }
};

export const phoneSignInSendSMS = async (phoneNumber: string) => {
  const phoneProvider = new PhoneAuthProvider(auth);
  const appVerifier = window.recaptchaVerifier;

  const verificationID = await phoneProvider.verifyPhoneNumber(
    phoneNumber,
    appVerifier
  );

  return verificationID;
};

export const phoneSignInConfirm = async (OTP: string, tryLink: boolean) => {
  const credential = PhoneAuthProvider.credential(window.verificationID, OTP);

  if (auth.currentUser?.isAnonymous && tryLink) {
    try {
      await linkWithCredential(auth.currentUser, credential);
    } catch (err: any) {
      if (err.code === "auth/account-exists-with-different-credential") {
        await signInWithCredential(auth, credential);
      } else {
        await handlePhoneSignInError(err);
      }
    }
  } else {
    return signInWithCredential(auth, credential);
  }
};

export const handlePhoneSignInError = async (err: any) => {
  if (err.code === "auth/account-exists-with-different-credential") {
    alert("Account exists but primary login method is not phone number.");
    return;
  }

  if (err.code === "auth/credential-already-in-use") {
    const pendingCred = err.credential;
    auth
      .signOut()
      .then(() => {
        return signInWithCredential(auth, pendingCred);
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  } else {
    alert(
      err?.message || "Something went wrong, are you connected to the internet?"
    );
  }
};
