import { useGlobalState } from "../../../contexts/GlobalContext"

const useDefaultMenuSection = () => {
    const { data: menuData } = useGlobalState()

    if (menuData) {
        const { defaultMenuSection, menu } = menuData

        const menuKeys = Object.keys(menu || {})

        if (!menu || !menuKeys.length) {
            return undefined
        }


        const isDefaultSectionValid = (defaultMenuSection && menu[defaultMenuSection]) ? true : false

        return isDefaultSectionValid ?
            defaultMenuSection :
            menuKeys.sort()[0]
    }

    return undefined
}

export default useDefaultMenuSection