import React from 'react'

import styles from './Box.module.scss';

interface IProps {
    style?: React.CSSProperties
}

const Box: React.FC<IProps> = props => {
    return <div className={styles.Container} style={{ ...props.style }}>
        {props.children}
    </div>
}

export default Box