import { initializeApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getAuth } from "firebase/auth";
import {
  getFirestore,
  doc,
  collection,
  getDoc as getFirestoreDoc,
  getDocs as getFirestoreDocs,
  setDoc as setFirestoreDoc,
  updateDoc as updateFirestoreDoc,
  query as firestoreQuery,
  where, WhereFilterOp,
} from "firebase/firestore"
import {
  getDatabase,
  ref,
  child,
  get as dbGet,
  set as dbSet,
  update as dbUpdate,
  DatabaseReference,
} from "firebase/database"
import { 
  getStorage, 
  ref as storageRef, 
} from "firebase/storage";

const dev = false

let firebaseConfig = {}

if (!dev) {
  // production
  firebaseConfig = {
    apiKey: "AIzaSyB0GPW90Z06gzzDcZy1YsWqtlxGHcX6HKU",
    authDomain: "sally-1578747294813.firebaseapp.com",
    databaseURL: "https://sally-1578747294813.firebaseio.com",
    projectId: "sally-1578747294813",
    storageBucket: "sally-1578747294813.appspot.com",
    messagingSenderId: "619902856949",
    appId: "1:619902856949:web:d20dc504e77a222dfc5fbc",
    measurementId: "G-X3CKGPE3PY",
    locationId: "europe-west3"
  };
} else {
  // dev
  firebaseConfig = {
    apiKey: "AIzaSyAD1xs9fUTtCLekMF4bmpcfZyqU4ltWPMQ",
    authDomain: "sally-dev-d372e.firebaseapp.com",
    databaseURL: "https://sally-dev-d372e.firebaseio.com",
    projectId: "sally-dev-d372e",
    storageBucket: "sally-dev-d372e.appspot.com",
    messagingSenderId: "1033119888074",
    appId: "1:1033119888074:web:ad7907d993107a94b33a33",
    locationId: "europe-west3"
  }
}

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

const functions = getFunctions(app, "europe-west3");
function api<TReq, TRes>(name: string) { return httpsCallable<TReq, TRes>(functions, name) };

const firestore = getFirestore(app);

const docRef = (path: string, id: string) => {
  return doc(firestore, path, id)
}

const collectionRef = (path: string) => {
  return collection(firestore, path)
}

const getDoc = async (path: string, id: string) => {
  const ref = docRef(path, id);
  const res = await getFirestoreDoc(ref);
  return res;
}

const getDocs = async (path: string) => {
  const ref = collection(firestore, path);
  const res = await getFirestoreDocs(ref)
  return res
}

const setDoc = (path: string, id: string, data: any) => {
  const ref = docRef(path, id);
  return setFirestoreDoc(ref, data)
}

const updateDoc = async (path: string, id: string, data: any) => {
  const ref = docRef(path, id);
  return updateFirestoreDoc(ref, data)
}

const queryFirestore = async (path: string, field: string, filterOp: WhereFilterOp, value: any) => {
  const ref = collectionRef(path)

  const q = firestoreQuery(ref, where(field, filterOp, value))

  const querySnapshot = await getFirestoreDocs(q)

  return querySnapshot
}

const db = getDatabase(app)

const dbRef = (path?: string) => ref(db, path)

const getChildRef = (path: string, parentRef?: DatabaseReference) => {
  return child(parentRef || dbRef(), path)
}

const getChild = async (ref: string, parentRef?: DatabaseReference) => {
  const childRef = getChildRef(ref, parentRef)
  const data = await dbGet(childRef)
  return data
}

const setChild = (ref: string, data: any, parentRef?: DatabaseReference) => {
  const childRef = getChildRef(ref, parentRef)
  return dbSet(childRef, data)
}

const updateChild = (ref: string, data: any, parentRef?: DatabaseReference) => {
  const childRef = getChildRef(ref)
  return dbUpdate(childRef, data)
}

const storage = getStorage(app);

const getStorageRef = (path: string) => {
  return storageRef(storage, path)
}

export {
  auth,
  api,
  firestore,
  docRef,
  collectionRef,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
  queryFirestore,
  db,
  dbRef,
  getChildRef,
  getChild,
  setChild,
  updateChild,
  storage,
  getStorageRef,
};

export default app;
