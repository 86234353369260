import React from 'react';
import { useGlobalState } from '../../../contexts/GlobalContext';

import TextInput from '../../../Components/TextInput';
import Button from '../../../Components/Button';
import Item from './Item';

import styles from './SelectItems.module.scss';

export interface IItem {
    id: string
    title: string
    fullPrice: number
    price: number
}

export interface IExPayment {
    name?: string
    amount: number
    date: string
}

interface IProps {
    visible: boolean
    onChange: (total: number) => void
    onClose: () => void
    items: IItem[]
    exPayments: { [id: string]: IExPayment }
    currency: string
    total: number
    checkTotal: number
    balance: number | undefined
}

const SelectItems: React.FC<IProps> = props => {
    const { currency, table_id } = useGlobalState().data!

    return <div className={`${styles.Container} ${!props.visible ? styles.hidden : ''}`}>
        <div className={styles.Header}>
            <h3>Table {table_id}</h3>

            {props.balance ? <span style={{ display: 'flex', alignItems: 'center', marginTop: 8 }}>
                <span className={styles.TotalLeftLabel}>{props.currency}{props.balance}</span>
                left to pay
            </span> : null}
        </div>

        <TextInput
            type='number'
            min='0.01'
            autofocus
            containerStyle={{ marginBottom: 16 }}
            fullWidth
            placeholder='0.00'
            left={() => {
                return <div>
                    {currency}
                </div>
            }}
            default={props.total.toString()}
            onChange={(text) => props.onChange(text ? parseFloat(text) : 0)}
            validateOnBlur={(text) => {
                if (text) {
                    const num = parseFloat(text)

                    let newNum = Math.round((num + Number.EPSILON) * 100) / 100

                    return newNum.toString()
                }

                return text
            }}
        />


        <Button
            color={2}
            fullWidth
            style={{ marginBottom: 16 }}
            onClick={props.onClose}
            disabled={props.total === 0}
        >
            PROCEED TO PAYMENT
        </Button>

        <div className={styles.PaymentsContainer}>
            {props.exPayments && Object.keys(props.exPayments).map(id => {
                const payment = props.exPayments[id]
                return <div key={id} style={{ fontSize: 14 }}>
                    {payment.name || 'Someone'} paid {currency}{payment.amount.toFixed(2)}
                </div>
            })}
        </div>

        <div className={styles.ItemsContainer}>
            <h3 style={{ textAlign: 'center' }}>Total: {currency}{props.checkTotal.toFixed(2)}</h3>
            {props.items.map((item) => {
                return <Item key={'item' + item.id} {...item} currency={currency} />
            })}
        </div>

        <div className={styles.Footer}>

            {/* <span className={styles.ClickText} onClick={() => setInputCustom(!inputCustom)}>or input custom amount</span> */}
        </div>
    </div>
}

export default SelectItems