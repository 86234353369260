import React, { useState } from 'react';
import { CardElement } from '@stripe/react-stripe-js';

import useStripeSubmit from './useStripeSubmit';

import { Button, TextField } from '@material-ui/core';
import FullscreenLoading from '../../../Components/FullScreenLoading';
import styles from './Stripe.module.scss';

export default function StripePayment(props) {
    const { loading, submitNewCard, errorMessage, total, currency, title, desc } = useStripeSubmit();

    const [focus, setFocus] = useState(false);
    const [inputNameValue, setInputNameValue] = useState('');

    return (
        <div className={styles.Container}>
            {total ? <div className={styles.CardContainer}>
                <form className={styles.CardForm} onSubmit={(e) => submitNewCard(e, inputNameValue)}>
                    {(title || desc) && (
                    <div className={styles.Top}>
                        {title && <div style={{textAlign: 'center', fontWeight: 'bolder'}}>
                            {title}
                        </div>}

                        {desc && <div className={styles.Description}> 
                            {desc}
                        </div>}
                    </div>)}

                    {renderNewCard()}

                    {!!errorMessage && <span className={styles.ErrorSpanFb}>{errorMessage} </span>}

                    <div className={styles.Bottom}>
                            Total:
                            <span className={styles.Price}> {total} {currency}</span>
                    </div>

                    <div className={styles.ActionBox}>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            size={"large"}
                            disabled={loading}
                            fullWidth
                        >
                            pay
                        </Button>
                    </div>
                </form>
            </div> : null}

            {loading && <FullscreenLoading />}
        </div>
    )

    function renderNewCard() {
        return <>
            <div className={styles.CardBox + ' ' + (focus ? styles.CardBoxFocus : '')}>
                <CardElement
                    className={styles.CardEl}
                    onFocus={() => setFocus(true)}
                    onBlur={() => setFocus(false)}
                />
            </div>

            <TextField
                id="outlined-basic"
                label="Name"
                variant="outlined"
                style={{ marginBottom: '25px' }}
                onChange={e => setInputNameValue(e.target.value)}
            />
        </>
    }
}