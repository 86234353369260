import * as React from 'react'

import RadioButton from '../../../RadioButton'

import RadioOptions from './Radio'
import CheckOptions from './Check'
import Tag from '../../../Tag'

import styles from "./ItemOptions.module.scss"

import { IItem } from '../../../../types/Menu'
import { ICartItem } from '../../../../types/Order'


interface IProps {
    type: "new" | "edit"
    options: IItem["options"]
    selectedOptions?: ICartItem["options"]
    verifyAge: boolean
    currency: string
    setConfirmedAge: (age: number) => void
    onRadioChange: (optionIndex: number, selectedIndex: number) => void
    onCheckChange: (optionIndex: number, indexes: number[]) => void
}

const ItemOptions: React.FC<IProps> = (props) => {
    const { options: optionsArray, verifyAge, currency } = props

    const [age, setAge] = React.useState(0)

    React.useEffect(() => {
        if (age) {
            props.setConfirmedAge(age)
        }
    }, [age])

    return <>
        {verifyAge && <div className={styles.SectionContainer}>
            <div style={{ flexDirection: 'row', alignItems: 'center' }}>
                <h4>
                    Age
                </h4>
                {/* <Tag color="#ff4040" text={i18n.t("required")} /> */}
            </div>

            <RadioButton
                index={1}
                label={"I am over 18 years old"}
                currency={currency}
                selected={age}
                setSelected={setAge}
            />
        </div>}

        {/* @todo show priority select */}

        {optionsArray && optionsArray.map((option, i) => {
            const extraProps: Record<string, any> = {}

            extraProps.currency = currency

            if (props.type === "edit" && props.selectedOptions) {
                extraProps.selectedOptions = props.selectedOptions[i]
            } else {
                extraProps.preselects = option.preselArray
            }

            if (option.type === 'radio') {
                return (
                    <div key={i} className={styles.SectionContainer}>
                        <div style={{
                            display: "flex",
                            alignItems: 'center'
                        }}
                        >
                            <h4 style={{ marginRight: 5 }}>
                                {option.title}
                            </h4>
                            {option.required ? <Tag color="#ff4040" text="REQUIRED" /> : null}
                        </div>
                        <RadioOptions
                            optionIndex={i}
                            titles={option.options}
                            price={option.price}
                            currency={currency}
                            onChange={props.onRadioChange}
                            {...extraProps}
                        />
                    </div>
                )
            } else if (option.type === 'check') {
                return (
                    <div key={i} className={styles.SectionContainer}>
                        <h4>
                            {option.title}
                        </h4>
                        {(option.min || option.max) ?
                            <div className={styles.OptionsSelectInstructions}>
                                {option.min === option.max ?
                                    `Please select exactly ${option.min} options` :
                                    <>
                                        {option.min ? <div>Minimum {option.min} options</div> : null}
                                        {option.max ? <div>Maximum {option.max} options</div> : null}
                                    </>
                                }
                            </div> : null}
                        <CheckOptions
                            optionIndex={i}
                            titles={option.options}
                            price={option.price}
                            preselected={option.preselArray}
                            min={option.min}
                            max={option.max}
                            currency={currency}
                            onChange={props.onCheckChange}
                            {...extraProps}
                        />
                    </div>
                )
            }
        })}
    </>
}

export default ItemOptions;
