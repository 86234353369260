import React from 'react';
import { Route, Routes as RouterRoutes } from 'react-router';
import Layout from '../Layout';

import LoginScreen from './Login';
import MenuIndex from './Menu';
import Payments from './Payments';
import MenuScreen from './Menu/View';
import SendOrderScreen from './Order/SendOrder';
import PayScreen from './Pay';
import PaySuccessScreen from './PaySuccess';
import OrderStatus from './Order/OrderStatus';
import ControlPanel from './ControlPanel';
import useDefaultMenuSection from './Menu/hooks/useDefaultMenuSection';

const Routes: React.FC = () => {
    return <RouterRoutes>
        <Route
            path={'/venue/:code'}
            element={<Layout />}
        >
            <Route index element={<ControlPanel />} />

            <Route
                path={'menu'}
                element={
                    <MenuIndex />
                }
            />

            <Route
                path={`menu/:section`}
                element={<MenuScreen />}
            />

            <Route
                path={`order`}
                element={<SendOrderScreen />}
            />

            <Route
                path={`pay`}
                element={<PayScreen />}
            />

            <Route
                path={`:action/success`}
                element={<PaySuccessScreen />}
            />

            <Route
                path={`status/:orderID`}
                element={<OrderStatus />}
            />
        </Route>

        <Route
            path={'/login'}
            element={<LoginScreen />}
        />

        <Route
            path={'/payments'}
            element={<Payments />}
        />
    </RouterRoutes>;
}

export default Routes;