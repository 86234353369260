import React from 'react';

const VeganSVGIcon = () => {
  return (
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 42.575 40">
        <g transform="translate(-126 -138)">
          <g transform="translate(107.998 -275.421)">
            <path className="a"
                  style={{fill: '#1b9e00'}}
                  d="M41.557,513.9s12.418-10.644-1.774-22.175c0,0-9.757-6.209-16.853-3.548,0,0-10.644,25.723,14.192,28.384C37.122,516.562,40.67,515.675,41.557,513.9Z"
                  transform="translate(-2.268 -63.141)"/>
            <path className="a"
                  style={{fill: '#1b9e00'}}
                  d="M139.529,449.2s15.745,4.427,17.466-13.778c0,0-.247-11.562-6.4-15.99,0,0-27.306,5.414-15.742,27.554C134.854,446.986,137.561,449.446,139.529,449.2Z"
                  transform="translate(-97.146 -5.122)"/>
            <g transform="translate(18.002 413.421)">
              <path
                d="M165.171,427.547a34.812,34.812,0,0,0-4.891-13.6l-.307-.524-.6.1c-9.184,1.484-15.4,5.393-18.471,11.619l1.591.785c2.738-5.547,8.309-9.1,16.562-10.551,6.41,11.264,4.578,18.473,2.891,21.793a11.907,11.907,0,0,1-9.573,6.571l.179,1.765a13.651,13.651,0,0,0,10.976-7.532A18.5,18.5,0,0,0,165.171,427.547Z"
                transform="translate(-122.733 -413.421)"/>
              <path
                d="M46.23,474.965c.924-.136,4.526-.558,6.534.78l.984-1.476a9.963,9.963,0,0,0-5.523-1.245,18.362,18.362,0,0,0,2.09-3.114,6.9,6.9,0,0,1,4.42-.219l.561-1.683a8,8,0,0,0-3.971-.217,30.054,30.054,0,0,0,1.929-6.734l-1.757-.251a28.869,28.869,0,0,1-1.382,5.173,7.227,7.227,0,0,0-3.345-3.756l-.842,1.562a5.849,5.849,0,0,1,2.988,4.945,16.986,16.986,0,0,1-2.053,3.144c-.369-1.875-1.305-4.429-3.678-5.708l-.842,1.562c2.3,1.237,2.826,4.329,2.949,5.709a9.322,9.322,0,0,1-2.115,1.313c-3.672-6.294-12.932-10.534-22.163-9.992l-.591.035-.195.559a34.08,34.08,0,0,0-2.19,12.951,16.649,16.649,0,0,0,2.906,8.845,15.046,15.046,0,0,0,6.452,5.113,19.77,19.77,0,0,0,7.746,1.541v-1.774c-5.355,0-10.122-2.208-12.752-5.907-3.224-4.536-3.456-11.311-.679-19.619A26.582,26.582,0,0,1,33.9,469.025c3.893,1.857,6.81,4.523,8.212,7.507,1.9,4.049.949,8.4-2.754,12.621a20.47,20.47,0,0,1-2.807-2,10.779,10.779,0,0,1,2.5-7.761l-1.385-1.108a12.973,12.973,0,0,0-2.9,7.179,23.872,23.872,0,0,1-2.6-3.31l.1-.028c-.009-.034-.888-3.443,2.312-7.443l-1.385-1.108a12.426,12.426,0,0,0-2.67,5.686,24.569,24.569,0,0,1-1.925-5.3l-1.741.34a25.577,25.577,0,0,0,2.028,5.681,10.378,10.378,0,0,0-5.73-.563l.348,1.74a9.732,9.732,0,0,1,6.92,1.591,26.41,26.41,0,0,0,2.005,2.763,14.879,14.879,0,0,0-4.846.06l.292,1.75c4.782-.8,7.2.951,7.3,1.026l.009-.012a22.257,22.257,0,0,0,3.915,2.721l.6.329.469-.5c4.383-4.7,5.709-9.835,3.776-14.54A11.048,11.048,0,0,0,46.23,474.965Z"
                transform="translate(-18.002 -453.802)"/>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
}

export default VeganSVGIcon;