import { useEffect, useState } from "react";
import { useGlobalState } from "../../contexts/GlobalContext";
import { Link, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import ModalLink from "../../Components/ModalLink";
import ActiveOrder from "../../Components/ActiveOrder";

import { BookOpen } from "react-feather";
import PayIcon from "../../assets/icons/pay.svg";

import styles from "./Control.module.scss";
import SuccessModal from "../../Components/SuccessModal";
import { Card, CardContent, CardMedia, Typography } from "@material-ui/core";
import useDefaultMenuSection from "../Menu/hooks/useDefaultMenuSection";
import {
  AcUnit,
  NotificationsOutlined,
  ReceiptOutlined,
} from "@material-ui/icons";
import RequestBillModal from "../../Components/RequestBillModal";
import RequestWaiterModal from "../../Components/RequestWaiterModal";
import RequestIceModal from "../../Components/RequestIceModal";

const ControlPanel: React.FC = (props) => {
  const location = useLocation();

  const { updateCart, data } = useGlobalState();
  const { name, coverURL, promo, pay, requestServices } = data!;

  const [modalActive, setModalActive] = useState(false);
  const [modalRequestWaiterVisible, setModalRequestWaiterVisible] =
    useState(false);
  const [modalRequestBillVisible, setModalRequestBillVisible] = useState(false);
  const [modalRequestIceVisible, setModalRequestIceVisible] = useState(false);

  const [modalSuccessData, setModalSuccessData] = useState<{
    title?: string;
    description?: string;
  }>();
  const [cookies, setCookies] = useCookies();

  const [activeOrders, setActiveOrders] = useState<string[]>([]);

  const defaultMenuSection = useDefaultMenuSection();

  useEffect(() => {
    let activeOrders = cookies.activeOrders;

    if (activeOrders && !Array.isArray(activeOrders)) {
      activeOrders = JSON.parse(activeOrders);
    }

    if (activeOrders) {
      setActiveOrders(activeOrders);
    }
  }, [cookies]);

  useEffect(() => {
    if (location.state) {
      const state = location.state as Record<string, any>;

      if (state.orderSent) {
        updateCart("CLEAR", {});
        window.history.replaceState({}, document.title);
        setTimeout(
          () =>
            setModalSuccessData({
              title: "Order sent!",
              description: "We hope you enjoy it :)",
            }),
          100
        );
      }
    }
  }, [location]);

  return (
    <div className={styles.ControlPanel}>
      <header className={styles.CoverImage}>
        <img src={coverURL} alt="cover image" />
      </header>

      <main className={styles.MainControl}>
        <div className={styles.SubBox} />

        <div className={styles.ActionsContainer}>
          <Link
            className={styles.Btn}
            to={`menu${defaultMenuSection ? "/" + defaultMenuSection : ""}`}
          >
            <BookOpen size={40} />

            <p className={styles.BtnText}>MENU</p>
          </Link>

          {pay && (
            <Link
              className={styles.Btn}
              to="pay"
              state={{
                from: "Control",
              }}
            >
              <img src={PayIcon} alt="pay" style={{ height: 50 }} />

              <p className={styles.BtnText}>PAY</p>
            </Link>
          )}

          {requestServices?.includes("waiter") && (
            <div
              className={styles.Btn}
              onClick={() => setModalRequestWaiterVisible(true)}
            >
              <NotificationsOutlined style={{ height: 40, fontSize: 40 }} />

              <p className={styles.BtnText}>WAITER</p>
            </div>
          )}

          {requestServices?.includes("ice") && (
            <div
              className={styles.Btn}
              onClick={() => setModalRequestIceVisible(true)}
            >
              <AcUnit style={{ height: 40, fontSize: 40 }} />

              <p className={styles.BtnText}>ICE</p>
            </div>
          )}

          {requestServices?.includes("bill") && (
            <div
              className={styles.Btn}
              onClick={() => setModalRequestBillVisible(true)}
            >
              <ReceiptOutlined style={{ height: 40, fontSize: 40 }} />

              <p className={styles.BtnText}>BILL</p>
            </div>
          )}
        </div>

        {activeOrders.map((orderID) => (
          <ActiveOrder id={orderID} />
        ))}

        {promo ? (
          <Card
            style={{
              width: "95%",
              borderRadius: 10,
              minHeight: "40%",
              maxHeight: "80%",
            }}
          >
            <CardMedia
              component="img"
              image={promo.imageURL}
              style={{
                maxHeight: "70%",
              }}
            />

            <CardContent>
              <Typography variant="body2" color="textSecondary">
                {name}
              </Typography>
              <Typography gutterBottom variant="h5" component="div">
                {promo.title}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {promo.text}
              </Typography>
            </CardContent>
          </Card>
        ) : null}
      </main>

      <ModalLink active={modalActive} setActive={setModalActive} />

      <RequestWaiterModal
        active={modalRequestWaiterVisible}
        setActive={setModalRequestWaiterVisible}
        onSuccess={(title, description) => {
          setModalSuccessData({ title, description });
        }}
      />

      <RequestBillModal
        active={modalRequestBillVisible}
        setActive={setModalRequestBillVisible}
        onSuccess={(title, description) => {
          setModalSuccessData({ title, description });
        }}
      />

      <RequestIceModal
        active={modalRequestIceVisible}
        setActive={setModalRequestIceVisible}
        onSuccess={(title, description) => {
          setModalSuccessData({ title, description });
        }}
      />

      <SuccessModal
        active={!!modalSuccessData}
        setActive={setModalSuccessData}
        title={modalSuccessData?.title}
        description={modalSuccessData?.description}
      />
    </div>
  );
};

export default ControlPanel;
