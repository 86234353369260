import { useState } from "react";
import icons from "../../../assets/icons/icons";

import AllergenIcon from "./AllergenIcon";

import VeganSVGIcon from "../../../assets/icons/VeganSVGIcon";
import VegetarianSVGIcon from "../../../assets/icons/Vegetarian.svg";

import styles from "./Allergens.module.scss";


interface IProps {
    index: any
    contains: string[]
    mayContain: string[]
    iconStyle?: React.CSSProperties
}

const Allergens: React.FC<IProps> = props => {
    const { index, contains, mayContain, iconStyle } = props

    const [showLabels, setShowLabels] = useState(false)

    return <>
        <div className={styles.AllergensList}>
            {contains.length > 0 &&
                <span
                    style={{
                        marginRight: 5
                    }}
                >
                    Contains:
                </span>
            }

            {contains.map((allergen, i) => {
                const icon = icons.allergens.selected[allergen]
                if (icon) {
                    return <AllergenIcon
                        key={`contains_${index}_${i}_${allergen}`}
                        label={allergen}
                        showLabel={showLabels}
                        onClick={(e) => {
                            e.stopPropagation()
                            setShowLabels(!showLabels)
                        }}
                    >
                        <img
                            src={`data:image/svg+xml;utf8,${encodeURIComponent(icon)}`}
                            style={{
                                ...iconStyle,
                                marginLeft: "auto"
                            }}
                        />
                    </AllergenIcon>
                }

                return null
            })}
        </div >

        <div className={styles.AllergensList}>
            {mayContain.length > 0 &&
                <span
                    style={{
                        marginRight: 5,
                    }}
                >
                    May contain:
                </span>
            }

            {mayContain.map((allergen, i) => {
                const icon = icons.allergens.selected[allergen]

                if (icon) {
                    return <AllergenIcon
                        key={`may-contain_${index}_${i}_${allergen}`}
                        label={allergen}
                        showLabel={showLabels}
                        onClick={(e) => {
                            e.stopPropagation()
                            setShowLabels(!showLabels)
                        }}
                    >
                        <img
                            src={`data:image/svg+xml;utf8,${encodeURIComponent(icon)}`}
                            style={{
                                ...iconStyle,
                                marginLeft: "auto"
                            }}
                        />
                    </AllergenIcon>
                }

                return null
            })}
        </div>
    </>
}

export default Allergens;
