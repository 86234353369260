import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useGlobalState } from "../../../contexts/GlobalContext";
import { randomIntFromInterval } from "../../../utils/common";

import { auth, getDoc } from "../../../firebase";
import { signInAnonymously, onAuthStateChanged, User } from "firebase/auth";
import {
  attemptPayment,
  sendCashOrder as api_sendCashOrder,
} from "../../../api";

import { getCartTotal } from "../../../utils/menu";

import Button from "../../../Components/Button";
import TipsModal from "../../Pay/ModalTips";
import Warning from "../../../Components/Warning";
import CircularLoader from "../../../Components/CircularLoader";
import CartDetails from "../../../Components/CartDetails";
import SelectPaymentMethod from "../../../Components/SelectPaymentMethod";
import LoginModal from "../../../Components/auth/LoginModal";

import { Edit, ArrowRight } from "react-feather";
import { CircularProgress } from "@material-ui/core";
import { Apple } from "@material-ui/icons";
import GoogleIcon from "../../../assets/icons/pay/google.svg";

import styles from "./SendOrder.module.scss";
import { useNavigate } from "react-router-dom";

const SendOrderScreen: React.FC = () => {
  const { code } = useParams<{ code: string }>();

  const navigate = useNavigate();

  const { data, cart } = useGlobalState();
  const {
    name: venueName,
    menu,
    logoURL,
    currency,
    venue_id,
    table_id,
    cashPayments,
    cashPaymentsCustomText,
    paymentsDisabled,
    authDisabled,
    tableLabel,
    extraCharge,
    style,
  } = data!;

  const [loading, setLoading] = useState(false);
  const [sendButtonLoading, setSendButtonLoading] = useState(true);

  const [orderSettings, setOrderSettings] = useState<Record<string, any>>();

  const [modalTipsShown, setModalTipsShown] = useState(false);
  const [modalLoginShown, setModalLoginShown] = useState(false);

  const [amount, setAmount] = useState(0);
  const [tips, setTips] = useState(0);

  const [selectedPM, setSelectedPM] = useState<
    "new_card" | "apple" | "google" | "cash" | string | null
  >(!paymentsDisabled ? "new_card" : "cash");
  const selectedPMIsCard =
    selectedPM &&
    selectedPM !== "apple" &&
    selectedPM !== "google" &&
    selectedPM !== "cash";
  const selectedPMIsCash = selectedPM === "cash";

  const total = amount + tips + (extraCharge?.amount || 0);

  const init = async (user: User) => {
    await getOrderSettings();
    setLoading(false);
    setSendButtonLoading(false);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (!user) {
        await signInAnonymously(auth);
      } else {
        init(user);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (selectedPM === "cash") {
      setTips(0);
    }
  }, [selectedPM]);

  const getOrderSettings = async () => {
    const settings = await getDoc(
      `venues/id${venue_id}/settings`,
      "order"
    ).then((doc) => doc.data());

    if (settings?.ordersDisabled) {
      alert("Uh-oh, looks like this venue has temporarily disabled orders :(");
    }

    setOrderSettings(settings);

    return settings;
  };

  useEffect(() => {
    if (cart.length > 0) {
      const cartTotal = getCartTotal(cart, menu);

      if (!cartTotal) {
        navigate(`/venue/${code}/`);
      }

      setAmount(cartTotal);
    } else {
      navigate(`/venue/${code}/`);
    }
  }, [cart]);

  const pay = async () => {
    let currentURL = window.location.origin + window.location.pathname;

    let successURL =
      currentURL + `/success?gif=${randomIntFromInterval(1, 11)}`;

    if (data?.selfService) {
      successURL =
        window.location.origin +
        `/venue/${code}/status/{orderID}?gif=${randomIntFromInterval(1, 11)}`;
    }

    const failURL = currentURL + "?paymentFailed=true";

    const paymentData = {
      v2: true,
      uid: auth.currentUser?.uid,
      fn: "",
      username: "",
      phoneNumber: auth.currentUser?.phoneNumber,
      email: auth.currentUser?.email,
      card_id: selectedPMIsCard ? selectedPM : null,
      v_id: venue_id,
      t_id: table_id,
      venue_name: venueName,
      cart: cart,
      amount: amount + (extraCharge?.amount || 0),
      tips: tips,
      total: total,
      payImmediately: !data!.selfService,
      self_service: data!.selfService,
      successReturnURL: successURL,
      failReturnURL: failURL,
    };

    await attemptPayment(paymentData)
      .then((res) => {
        if (res.link) {
          window.location.href = res.link;
        } else {
          throw new Error("function error");
        }
      })
      .catch((err: any) => {
        setSendButtonLoading(false);
        alert("An unexpected error has occured");
      });
  };

  const sendCashOrder = async () => {
    if (!authDisabled) {
      if (!auth.currentUser || auth.currentUser?.isAnonymous) {
        setModalLoginShown(true);
        setSendButtonLoading(false);
        return;
      }
    }

    const displayName = auth.currentUser?.displayName;

    await api_sendCashOrder({
      v_id: venue_id,
      venue_name: venueName,
      t_id: table_id,
      total: total,
      cart: cart,
      username: "",
      fn: displayName ? displayName.split(" ")[0] : "",
      ln: displayName ? displayName.split(" ")[1] : "",
      self_service: !!data?.selfService,
    })
      .then((res) => {
        // res is the orderID
        if (data?.selfService) {
          const successURL =
            window.location.origin +
            `/venue/${code}/status/${res}?gif=${randomIntFromInterval(1, 11)}`;
          window.location.href = successURL;
        } else {
          navigate(`/venue/${code}/`, { state: { orderSent: true } });
        }
      })
      .catch((err: any) => {
        setSendButtonLoading(false);
        alert(
          "Oops, sorry, could not send the order, seems like the venue is experiencing some issues with their system. Please contact a waiter"
        );
      });
  };

  if (loading) {
    return (
      <div style={{ width: "80%", alignSelf: "center", textAlign: "center" }}>
        <CircularLoader />
      </div>
    );
  }

  return (
    <div className={styles.Container}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          margin: "10px 0",
          fontSize: 16,
        }}
      >
        {orderSettings?.warning ? (
          <Warning
            style={{
              marginBottom: 15,
            }}
            text={orderSettings.warning}
          />
        ) : null}

        <img
          src={logoURL}
          alt="venue logo"
          className={styles.Logo}
          style={{
            backgroundColor: style?.primary,
          }}
        />

        <h3>{venueName}</h3>

        {table_id && (
          <div>
            {tableLabel || "Table"} {table_id}
          </div>
        )}

        {cart.length > 0 ? (
          <div style={{ width: "90%", marginTop: 14 }}>
            <h4 style={{ marginBottom: 10 }}>Order Details</h4>
            <CartDetails
              cart={cart}
              menu={menu}
              currency={currency}
              disabled={sendButtonLoading}
            />
          </div>
        ) : null}

        <div style={{ width: "90%", marginTop: 14 }}>
          <h4 style={{ marginBottom: 10 }}>Payment Total</h4>
          <div className={styles.Row}>
            <div style={{ flex: 1 }}>Subtotal (including tax)</div>
            <div>
              {currency}
              {amount.toFixed(2)}
            </div>
          </div>

          {extraCharge && (
            <div className={styles.Row}>
              <div style={{ flex: 1 }}>
                {extraCharge.reason || "Extra charge"}
              </div>
              <div>
                {currency}
                {extraCharge.amount.toFixed(2)}
              </div>
            </div>
          )}

          {selectedPM !== "cash" ? (
            <div
              className={`${styles.Row} touchable`}
              onClick={() => {
                if (!sendButtonLoading) {
                  setModalTipsShown(true);
                }
              }}
            >
              <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
                Tips
                <Edit
                  size={18}
                  color="#808080"
                  strokeWidth={3}
                  style={{ marginLeft: 10 }}
                />
              </div>

              <div>
                {currency}
                {tips.toFixed(2)}
              </div>
            </div>
          ) : null}

          <div className={styles.Row} style={{ fontWeight: 600 }}>
            <div style={{ flex: 1 }}>Total</div>
            <div>
              {currency}
              {total.toFixed(2)}
            </div>
          </div>

          <div className={styles.PaymentMethodContainer}>
            <h4 style={{ marginBottom: 20 }}>Select Payment Method</h4>

            {/* <div className={`${styles.PaymentMethod} ${selectedPM === 'apple' ? styles.selected : ''}`} onClick={() => setSelectedPM('apple')}>
                        <Apple style={{ marginRight: 10 }} />
                        <div style={{ flex: 1 }}>
                            Apple Pay
                        </div>

                        {selectedPM === 'apple' && <CheckCircle color='#2BBF00' />}
                    </div>

                    <div className={`${styles.PaymentMethod} ${selectedPM === 'google' ? styles.selected : ''}`} onClick={() => setSelectedPM('google')}>
                        <img src={GoogleIcon} alt='google logo' style={{ marginRight: 10, height: 22 }} />
                        <div style={{ flex: 1 }}>
                            Google Pay
                        </div>

                        {selectedPM === 'google' && <CheckCircle color='#2BBF00' />}
                    </div> */}

            <SelectPaymentMethod
              venue_id={venue_id}
              paymentsDisabled={paymentsDisabled}
              cashPayments={cashPayments}
              cashPaymentsCustomText={cashPaymentsCustomText}
              selected={selectedPM}
              setSelected={(pm) => {
                setSelectedPM(pm);
              }}
              onClickLogin={() => {
                setModalLoginShown(true);
              }}
              loading={sendButtonLoading}
            />

            <Button
              color={selectedPMIsCard || selectedPMIsCash ? 2 : "#000"}
              style={{ marginTop: 25 }}
              disabled={!selectedPM || sendButtonLoading}
              onClick={async () => {
                setSendButtonLoading(true);

                const settings = await getOrderSettings();

                if (settings?.ordersDisabled) {
                  setSendButtonLoading(false);
                  return;
                }

                if (selectedPMIsCash) {
                  sendCashOrder();
                } else {
                  pay();
                }
              }}
            >
              {!sendButtonLoading ? (
                <>
                  {selectedPM === "apple" && (
                    <Apple style={{ fontSize: 30, marginRight: 10 }} />
                  )}
                  {selectedPM === "google" && (
                    <img
                      src={GoogleIcon}
                      alt="google logo"
                      style={{ height: 30, marginRight: 10 }}
                    />
                  )}
                  {selectedPMIsCard
                    ? "PAY WITH CARD"
                    : selectedPM === "apple"
                    ? "Apple Pay"
                    : selectedPM === "google"
                    ? "Google Pay"
                    : selectedPM === "cash"
                    ? "SEND ORDER"
                    : "PAY"}
                </>
              ) : (
                <CircularProgress
                  style={{
                    color: "inherit",
                    height: 30,
                    width: 30,
                    marginLeft: "46.5%",
                  }}
                />
              )}
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  gap: 8,
                }}
              >
                {currency}
                {total.toFixed(2)}
                <ArrowRight />
              </div>
            </Button>
          </div>
        </div>
      </div>

      <TipsModal
        visible={modalTipsShown}
        setActive={setModalTipsShown}
        currency={currency}
        total={total}
        tips={tips}
        onChange={(num) => {
          setTips(num);
        }}
      />

      <LoginModal
        tryLinkAccount={false}
        visible={modalLoginShown}
        setVisible={setModalLoginShown}
        onLogin={() => {
          setModalLoginShown(false);
        }}
      />
    </div>
  );
};

export default SendOrderScreen;
