import { default as DefaultModal, IModalProps } from "../Modal";
import Button from "../Button";

import { Check } from "react-feather";
import { useEffect, useState } from "react";

interface IProps extends IModalProps {
  title?: string;
  description?: string;
}

const SuccessModal: React.FC<IProps> = (props) => {
  const { ...rest } = props;

  const [title, setTitle] = useState(props.title || "Order sent!");
  const [description, setDescription] = useState(props.description || "Hope you enjoy it :)");

  useEffect(() => {
    if (props.title) {
      setTitle(props.title);
    }

    if (props.description) {
      setDescription(props.description);
    }
  }, [props.title, props.description])

  return (
    <DefaultModal
      {...rest}
      style={{
        minHeight: "unset",
      }}
    >
      <Check color="#2BBF00" size={100} />

      <h2>{title}</h2>

      <p style={{ textAlign: "center" }}>
        {description}
      </p>

      <Button
        style={{
          marginTop: 20,
        }}
        onClick={() => {
          props.setActive(false);
        }}
      >
        OK
      </Button>
    </DefaultModal>
  );
};

export default SuccessModal;
