import { ISessionData } from "./contexts/GlobalContext";
import { api } from "./firebase";
import ICard from "./types/Card";
import { Menu } from "./types/Menu";
import { Cart } from "./types/Order";

interface ICheckIfUserExistsReq {
    phoneNumber: string
}

export const checkIfUserExists = async (req: ICheckIfUserExistsReq) => {
    const checkIfUserExistsFunc = api<ICheckIfUserExistsReq, any>('checkIfUserExists')

    const res = await checkIfUserExistsFunc(req).then(res => res.data)

    return res
}

interface IRegisterReq {

}

export const register = async (req: any) => {
    const registerFunc = api<any, any>("register")

    const res = await registerFunc({ ...req, os: 'web' }).then(res => res.data)

    return res
}

interface IGetVenueMenuReq {
    code: string
    loaded: boolean
}

export const getVenueMenu = async (req: IGetVenueMenuReq) => {
    const getVenueMenuFunc = api<IGetVenueMenuReq, ISessionData>("venue-menu-get")

    const res = await getVenueMenuFunc(req).then(res => res.data)

    return res
}

interface IGetCardsReq {
    v_id: string
}

interface IGetCardsRes {
    [id: string]: ICard
}

export const getCards = async (req: IGetCardsReq) => {
    const getCardsFunc = api<IGetCardsReq, IGetCardsRes>('getCards')

    const res = await getCardsFunc(req).then(res => res.data)

    return res
}

interface IGetCheckReq {
    code: string
}

export const getCheck = async (req: IGetCheckReq) => {
    const getCheckFunc = api<IGetCheckReq, any>('venue-check-get')

    const res = await getCheckFunc(req).then(res => res.data)

    return res
}

interface ITranslateMenuReq {
    source: string
    target: string
    v_id?: string
}

interface ITranslateMenuRes {
    menu: Menu
    translated: boolean
}

export const translateMenu = async (req: ITranslateMenuReq) => {
    const translateFunc = api<ITranslateMenuReq, ITranslateMenuRes>('venue-menu-translate')

    const res = await translateFunc(req).then(res => res.data)

    return res
}

interface IAttemptPaymentReq {
    v2: boolean
    uid?: string
    fn?: string
    username?: string
    phoneNumber?: string | null
    email?: string | null
    card_id: string | null,
    v_id: string
    t_id: string
    venue_name?: string
    cart?: Cart
    amount: number
    tips: number
    total: number
    payImmediately?: boolean
    self_service?: boolean
    successReturnURL: string,
    failReturnURL: string
}

interface IAttemptPaymentRes {
    link?: string
}

export const attemptPayment = async (req: IAttemptPaymentReq) => {
    const getCheckFunc = api<IAttemptPaymentReq, any>('attemptPayment')

    const res = await getCheckFunc(req).then(res => res.data)

    return res
}

interface IStripeCreateIntent {
    orderID?: string
}

export const stripeCreateIntent = async (req: IStripeCreateIntent) => {
    const createIntentFunc = api<IStripeCreateIntent, any>('payments-stripe-createIntent')

    const res = await createIntentFunc(req).then(res => res.data)

    return res
}

interface ISendCashOrderReq {
    v_id: string
    venue_name: string
    t_id?: string
    total: number
    cart: Cart
    username: string
    fn: string
    ln: string
    isMember?: boolean
    self_service: boolean
}

export const sendCashOrder = async (data: ISendCashOrderReq) => {
    const addFunc = api<ISendCashOrderReq, string>('addToTab')

    const res = await addFunc(data).then(res => res.data)

    return res
}
