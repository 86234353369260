import { useEffect, useRef, useState } from "react";
import { getCartItemPrice } from "../../../utils/menu";

import Image from "../../Image";
import DietPreferences from "../Allergens/DietPreferences";
import Allergens from "../Allergens";
import ItemOptions from "./ItemOptions";
import TextInput from "../../TextInput";
import InputSpinner from "../../InputSpinner";

import { IconButton } from "@material-ui/core";

import { PlusCircle, X } from "react-feather";

import styles from "./ItemModal.module.scss";

import { ICartItem } from "../../../types/Order";
import { IItem, isRadioOption } from "../../../types/Menu";

interface IProps {
  type: "new" | "edit";
  item: IItem;
  initialData?: {
    options: ICartItem["options"];
    comments: string;
    quantity: number;
    priority: number;
  };
  section: string;
  currency: string;
  addToCart: (cartItem: ICartItem) => void;
  onClose: () => void;
}

const ItemModal: React.FC<IProps> = (props) => {
  const { type, item, currency, onClose } = props;

  const {
    tags,
    options,
    title,
    translatedTitle,
    desc,
    translatedDesc,
    price,
    unit,
    imageURL,
    type: itemTypes,
  } = item;

  const verifyAge = tags && tags.includes("alcohol");

  const [quantity, setQuantity] = useState(props.initialData?.quantity || 1);

  const [total, setTotal] = useState(Number(price));

  const selectedOptions = useRef<ICartItem["options"] | undefined>(
    props.initialData?.options
  );
  const confirmedAge = useRef(false);
  const priority = useRef(
    props.initialData?.priority !== undefined ? props.initialData.priority : 2
  );
  const comments = useRef(props.initialData?.comments || "");

  const addingToCart = useRef(false);

  const getCartItem = () => {
    const temp: ICartItem = {
      section: props.section,
      id: item.id,
      // 'catNumber': props.catNum,
      // 'itemNumber': props.itemNum,
      quantity: quantity,
      options: selectedOptions.current!,
      priority: priority.current,
      comments: comments.current,
      date: new Date().getTime(),
    };

    return temp;
  };

  const onChange = () => {
    const currentCartItem = getCartItem();

    const tempTotal = getCartItemPrice(currentCartItem, item);

    setTotal(tempTotal);
  };

  useEffect(() => {
    onChange();
  }, [quantity]);

  useEffect(() => {
    if (!selectedOptions.current) {
      const optionsArray = options;
      const temp: any[] = [];
      for (let i = 0; i < optionsArray.length; i++) {
        const obj: Record<string, any> = {
          title: null,
          indexes: null,
        };

        if (optionsArray[i].type === "radio") {
          obj.indexes = -1;
        } else {
          obj.indexes = [];
        }

        obj.title = optionsArray[i].title;
        temp[i] = obj;
      }

      selectedOptions.current = temp;
    } else {
      onChange();
    }
  }, []);

  const onRadioChange = (optionIndex: number, selectedIndex: number) => {
    let temp = selectedOptions.current!;
    temp[optionIndex].indexes = selectedIndex;
    selectedOptions.current = temp;

    onChange();
  };

  const onCheckChange = (optionIndex: number, indexes: number[]) => {
    let temp = selectedOptions.current!;
    temp[optionIndex].indexes = [...indexes];
    selectedOptions.current = temp;

    onChange();
  };

  const addToCart = () => {
    if (addingToCart.current) {
      return;
    }

    if (verifyAge && !confirmedAge.current) {
      alert("Please confirm that you are over 18 years old by ticking the box");

      return;
    }

    const itemRef = item;

    let requiredSelectionErrors = "";
    const minMaxErrors: string[] = [];

    const selected = selectedOptions.current!;

    const temp = getCartItem();

    for (let i = 0; i < itemRef.options.length; i++) {
      const itemOptions = itemRef.options[i];

      if (isRadioOption(itemOptions)) {
        if (itemOptions.required) {
          let found = false;
          for (let j = 0; j < selected.length; j++) {
            if (
              selected[j].title === itemRef.options[i].title &&
              selected[j].indexes !== null &&
              selected[j].indexes !== -1
            ) {
              found = true;
              break;
            }
          }
          if (!found) {
            requiredSelectionErrors =
              requiredSelectionErrors +
              itemRef.options[i].title +
              (itemRef.options.length - 1 !== i ? ", " : "");
          }
        }
      } else {
        if (itemOptions.min || itemOptions.max) {
          const indexes = selected[i].indexes;

          if (
            selected[i].title === itemRef.options[i].title &&
            indexes !== null &&
            Array.isArray(indexes)
          ) {
            if (itemOptions.min === itemOptions.max) {
              if (indexes.length !== itemOptions.min) {
                minMaxErrors.push(
                  `Please select exactly ${itemOptions.min} options for ${itemOptions.title}`
                );
              }
            } else {
              if (itemOptions.min && indexes.length < itemOptions.min) {
                minMaxErrors.push(
                  `Please select at least ${itemOptions.min} options for ${itemOptions.title}`
                );
              }
            }
          }
        }
      }
    }

    if (requiredSelectionErrors || minMaxErrors.length) {
      let errors = "";

      if (requiredSelectionErrors) {
        errors += "Please choose an option for: " + requiredSelectionErrors;
      }

      if (minMaxErrors.length) {
        if (errors) {
          errors += "\n";
        }

        errors += minMaxErrors.join("\n");
      }

      alert(errors);
    } else {
      addingToCart.current = true;
      props.addToCart(temp);
      addingToCart.current = false;
    }
  };

  if (!item) {
    return null;
  }

  return (
    <section className={styles.Container}>
      <div className={styles.Header}>
        <IconButton onClick={onClose}>
          <X color="black" strokeWidth={3} />
        </IconButton>
      </div>

      <div className={styles.Content}>
        {imageURL && (
          <Image
            src={imageURL}
            className={styles.Image}
            containerStyle={{
              minHeight: "25vh",
              width: "90%",
              borderRadius: 10,
            }}
            style={{
              borderRadius: 10,
            }}
            alt="item image"
          />
        )}

        <div className={styles.Body}>
          <h2 style={{ color: "#000" }}>
            {translatedTitle ? translatedTitle : title}
          </h2>

          <p style={{ marginBottom: 10 }}>
            {translatedDesc ? translatedDesc : desc}
          </p>

          {price && Number(price) > 0 && (
            <p className={styles.Price}>
              {currency} {price} {unit ? " / " + unit : ""}
            </p>
          )}

          <DietPreferences
            index={0}
            types={itemTypes || []}
            iconStyle={{
              height: 24,
              width: 24,
            }}
          />

          <Allergens
            index={1}
            contains={item.contains || []}
            mayContain={item.maycontain || []}
            iconStyle={{
              height: 28,
              width: 28,
              zIndex: 1,
            }}
          />

          <ItemOptions
            type={type}
            options={options}
            selectedOptions={selectedOptions.current}
            currency={currency}
            verifyAge={verifyAge}
            setConfirmedAge={(age) => {
              confirmedAge.current = age ? true : false;
            }}
            onRadioChange={onRadioChange}
            onCheckChange={onCheckChange}
          />

          <TextInput
            className={styles.InputComment}
            containerStyle={{
              width: "100%",
              backgroundColor: "#F2F2F2",
              border: "none",
              marginTop: "5%",
            }}
            placeholder="Leave a note..."
            default={props.initialData?.comments}
            maxLength={32}
            onChange={(text) => {
              comments.current = text;
            }}
          />

          <InputSpinner
            containerStyle={{
              alignSelf: "center",
              marginTop: "7.5%",
              marginBottom: 200,
            }}
            num={quantity}
            min={1}
            step={1}
            onChange={(num) => {
              setQuantity(num);
            }}
          />
        </div>
      </div>

      <div className={styles.BtnSave} onClick={addToCart}>
        <div>{type === "new" ? "ADD TO CART" : "SAVE"}</div>
        <PlusCircle size={16} style={{ marginLeft: 6 }} />

        <div style={{ flex: 1 }} />
        <div>
          {currency} {total.toFixed(2)}
        </div>
      </div>
    </section>
  );
};

export default ItemModal;
