import { Card, CardMedia } from "@material-ui/core";
import { NavHashLink } from "react-router-hash-link";
import convertAnchors from "../../../utils/convertAnchor";
import { ICategory } from "../../../types/Menu";
import styles from "./CategoryImages.module.scss"

interface IProps {
    categories: ICategory[]
}

const CategoryImages: React.FC<IProps> = props => {
    const { categories } = props

    return <div className={styles.Container}>
        {categories.map((category, index) => {
            if (!category.imageURL) {
                return null
            }

            return <NavHashLink
                key={`cat_${index}_${category.catTitle}`}
                to={`#${convertAnchors(category.catTitle)}`}
            >
                <Card
                    style={{
                        minWidth: 140,
                        maxWidth: 140,
                        marginBottom: 20,
                        marginLeft: 4,
                        fontSize: 14,
                        fontWeight: 'bold'
                    }}
                    onClick={() => {
                    }}
                >
                    <CardMedia
                        component="img"
                        height={100}
                        image={category.imageURL}
                        alt={category.catTitle}
                    />

                    <div className={styles.CategoryTitle + " ellipsis"}>
                        {category.catTitle}
                    </div>
                </Card>
            </NavHashLink>
        })}
    </div>
}

export default CategoryImages;
