import { useState, useEffect } from "react";

import { auth } from "../../firebase";
import { onAuthStateChanged } from "firebase/auth";
import { getCards } from "../../api";

import PaymentCard from "../PaymentCard";
import { Link } from "@material-ui/core";

import { CreditCard, CheckCircle } from "react-feather";
import { Toll } from "@material-ui/icons";

import styles from "./SelectPaymentMethod.module.scss";

import ICard from "../../types/Card";

interface IProps {
  venue_id: string;
  paymentsDisabled: boolean;
  cashPayments?: boolean;
  cashPaymentsCustomText?: string;
  selected: string | null;
  setSelected: (pm: string) => void;
  loading: boolean;
  onClickLogin?: () => void;
}

const SelectPaymentMethod: React.FC<IProps> = (props) => {
  const {
    venue_id,
    paymentsDisabled,
    cashPayments,
    selected,
    setSelected,
    loading: paymentLoading,
  } = props;

  const [cards, setCards] = useState<{ [id: string]: ICard }>({});

  const [isUserAnonymous, setIsUserAnonymous] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setIsUserAnonymous(user ? user.isAnonymous : true);

      if (user && !user.isAnonymous && !paymentsDisabled) {
        await getCards({ v_id: venue_id }).then((res) => {
          if (res && Object.keys(res).length > 0) {
            setCards(res);
          }
        });
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <>
      {!paymentsDisabled && (
        <div
          className={`${styles.PaymentMethod} touchable ${
            selected === "new_card" ? styles.selected : ""
          }`}
          onClick={() => setSelected("new_card")}
        >
          <CreditCard style={{ marginRight: 10 }} />
          <div style={{ flex: 1 }}>+ Enter card details</div>

          {selected === "card" && <CheckCircle color="#2BBF00" />}
        </div>
      )}

      {Object.keys(cards).map((id) => (
        <PaymentCard
          key={id}
          data={cards[id]}
          selected={selected === id}
          onClick={() => {
            if (!paymentLoading) {
              setSelected(id);
            }
          }}
        />
      ))}

      {cashPayments && (
        <div
          className={`${styles.PaymentMethod} touchable ${
            selected === "cash" ? styles.selected : ""
          }`}
          onClick={() => setSelected("cash")}
        >
          <Toll style={{ marginRight: 8 }} />
          <div style={{ flex: 1 }}>
            {props.cashPaymentsCustomText || "Pay with cash"}
          </div>

          {selected === "cash" && <CheckCircle color="#2BBF00" />}
        </div>
      )}

      {!paymentsDisabled && isUserAnonymous && props.onClickLogin && (
        <div style={{ textAlign: "center" }}>
          <div style={{}}>Already have a Sally account?</div>
          <Link onClick={props.onClickLogin}>Login to view your cards</Link>
        </div>
      )}
    </>
  );
};

export default SelectPaymentMethod;
