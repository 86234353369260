import { useState } from "react";

import AllergenIcon from "../AllergenIcon";

import VeganSVGIcon from "../../../../assets/icons/VeganSVGIcon";
import VegetarianSVGIcon from "../../../../assets/icons/Vegetarian.svg";

import styles from "../Allergens.module.scss";

interface IProps {
    index: any
    types: string[]
    iconStyle?: React.CSSProperties
}

const DietPreferences: React.FC<IProps> = props => {
    const { index, types, iconStyle } = props

    const [showLabels, setShowLabels] = useState(false)

    return <>
        <div className={styles.AllergensList}>
            {types.map((type, i) => {
                switch (type) {
                    case "vegan":
                        return <AllergenIcon
                            key={`vegan_${index}_${i}`}
                            label={"vegan"}
                            showLabel={showLabels}
                            onClick={(e) => {
                                e.stopPropagation()
                                setShowLabels(!showLabels)
                            }}
                        >
                            <VeganSVGIcon />
                        </AllergenIcon>
                    case "vegetarian":
                        return <AllergenIcon
                            key={`vegetarian_${index}_${i}`}
                            label={"vegetarian"}
                            showLabel={showLabels}
                            onClick={(e) => {
                                e.stopPropagation()
                                setShowLabels(!showLabels)
                            }}
                        >
                            <img
                                src={VegetarianSVGIcon}
                                alt="Vegetarian"
                                style={{
                                    ...iconStyle,
                                    marginLeft: "auto"
                                }}
                            />
                        </AllergenIcon>
                }
            })}
        </div>
    </>
}

export default DietPreferences;
