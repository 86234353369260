import * as React from 'react'
import { Check } from 'react-feather';

import styles from "./RadioButton.module.scss"

interface IProps {
    index: number
    selected: number
    label: string
    price?: number | string
    currency: string
    setSelected: (index: number) => void
}

const RadioButton: React.FC<IProps> = (props) => {
    return (
        <div
            className={styles.Container}
            onClick={() => { props.setSelected(props.index); }}
        >
            {props.selected == props.index ?
                <div className={`${styles.RadioCircle} ${styles.selected}`} >
                    <Check color="white" strokeWidth={3} />
                </div>
                : <div className={`${styles.RadioCircle}`} />}

            <div style={{ paddingLeft: 10 }}>
                {props.label}
                {props.price !== "" && props.price ?
                    <span className="muted" style={{ marginLeft: 5 }}>
                        {props.price + props.currency}
                    </span> : null}
            </div>
        </div>
    );
}

export default RadioButton;
