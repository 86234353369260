import { Cart } from "../../types/Order"

export type UpdateCartActions = "ADD" | "DELETE" | "CLEAR" | "SET_ITEM"

const updateCartHandler = (state: Cart, type: UpdateCartActions, payload: any) => {
    switch (type) {
        case "ADD": {

            return [
                ...state,
                payload
            ]
        }
        case "DELETE": {
            const cart = [...state]

            const index = payload

            cart.splice(index, 1)

            return cart
        }
        case "SET_ITEM": {
            const cart = [...state]

            const { index, item } = payload

            cart[index] = item

            return cart
        }
        case "CLEAR": {
            return []
        }
        default:
            return state
    }
}

export default updateCartHandler;
