
import { ShoppingCart } from "react-feather";

import styles from "./CartButton.module.scss";

interface IProps {
    num: number
    onClick: () => void
}

const CartButton: React.FC<IProps> = props => {

    return <div className={styles.BtnCart} onClick={props.onClick}>
        <div style={{
            backgroundColor: '#2BBF00',
            borderRadius: 20,
            paddingLeft: 10,
            paddingRight: 10,
            paddingTop: 4,
            paddingBottom: 4,
            position: 'absolute',
            top: -3,
            right: -3,
            zIndex: 2,
            color: "white",
            fontWeight: "bold",
            fontSize: 14
        }}
        >
            {props.num}
        </div>

        <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%"
        }}>
            <ShoppingCart
                strokeWidth={3}
                className={styles.CartIcon}
                style={{
                    width: 30,
                    height: 30
                }}
            />
        </div>
    </div>
}

export default CartButton;
