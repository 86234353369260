import React from 'react';

import DefaultModal from '../../../Components/Modal';
import Button from '../../../Components/Button';

import { Plus } from 'react-feather';

import useWindowDimensions from '../../../hooks/useWindowDimensions';

import styles from './ModalOverpayment.module.scss';

const OverpaymentModal = ({ visible, setActive, currency, total, balance, showBill, addAsTip, payOnly }) => {

    const { width } = useWindowDimensions()

    return <DefaultModal active={visible} setActive={setActive} reducePadding disableCloseOnBackClick>
        <div style={{ flex: 1, width: width * 0.8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {/* <img src={CuteIcon} style={{ height: 64, marginTop: 12 }} /> */}
            <h4 style={{ textAlign: 'center', marginBottom: 4 }}>Thank you for being so generous!</h4>
            <div className={styles.Desc}>You offered to pay more than what's left to pay</div>

            <div className={styles.MidContainer}>
                exceeding amount
                <h1 className={styles.Amount}>
                    {currency}{total.toFixed(2)}
                </h1>
                <Button
                    onClick={() => addAsTip(total)}
                >
                    <Plus size={20} style={{ marginRight: 8 }} /> add as a tip :)
                </Button>
            </div>

            <Button
                color={2}
                fullWidth
                style={{ marginTop: 24, marginBottom: 4 }}
                onClick={() => payOnly(balance)}
            >
                Pay only {currency}{balance}
            </Button>
            <span className={styles.ClickText} onClick={showBill}>back to the bill</span>
        </div>
    </DefaultModal>
}

export default OverpaymentModal