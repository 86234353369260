import { AlertCircle } from "react-feather"

interface IProps {
    style?: React.CSSProperties
    text: string
}

const Warning: React.FC<IProps> = props => {
    const { style, text } = props

    return <div
        style={{
            width: '90%',
            display: "flex",
            alignSelf: 'center',
            flexDirection: "row",
            alignItems: "center",
            marginTop: 20,
            borderRadius: 20,
            border: "2px solid #F29339",
            paddingTop: 10,
            paddingBottom: 10,
            ...style
        }}
    >
        <AlertCircle
            color="#F29339"
            size={24}
            style={{ marginLeft: 10, marginRight: 10 }}
        />
        <div style={{
            color: "#F29339",
            fontWeight: "bolder"
        }}>
            {text}
        </div>
    </div>
}

export default Warning;
