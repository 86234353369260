import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

import { Button, CircularProgress } from "@material-ui/core";

import styles from "./ActiveOrder.module.scss";

import { IStatus } from "../../Routes/Order/OrderStatus";
import { getChild } from "../../firebase";

interface IProps {
  id: string;
}

const ActiveOrder: React.FC<IProps> = (props) => {
  const { id } = props;
  const navigate = useNavigate();
  const [cookies, setCookies] = useCookies();

  const [status, setStatus] = useState<IStatus>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const init = async () => {
      const statusSnapshot = await getChild(`orders/status/${id}`).then(
        (snapshot) => snapshot.val()
      );

      if (statusSnapshot) {
        setStatus(statusSnapshot);
      } else {
        const activeOrders = cookies.activeOrders;

        if (activeOrders && Array.isArray(activeOrders)) {
          const index = activeOrders.indexOf(id);

          if (index > -1) {
            activeOrders.splice(index, 1);
          }

          setCookies("activeOrders", activeOrders);
        }
      }

      setLoading(false);
    };

    init();
  }, [cookies.activeOrders, id, setCookies]);

  if (!loading && !status) {
    return null;
  }

  return (
    <div className={styles.Container}>
      {/* Green activity dot */}
      <div
        style={{
          height: 10,
          width: 10,
          borderRadius: 10,
          backgroundColor: "#2BBF00",
          marginRight: 5,
        }}
      />

      <h4
        style={{
          marginRight: 10,
          color: "#2BBF00",
        }}
      >
        Active Order
      </h4>
      {loading ? (
        <CircularProgress
          size={20}
          style={{
            color: "gray",
          }}
        />
      ) : (
        <div
          style={{
            color: "gray",
          }}
        >
          #{status?.orderID}
        </div>
      )}

      <Button
        variant="text"
        style={{ marginLeft: "auto", color: "#000" }}
        size="small"
        onClick={() => {
          navigate(`status/${id}`);
        }}
      >
        View Status
      </Button>
    </div>
  );
};

export default ActiveOrder;
