import { useState, ImgHTMLAttributes, useRef, useEffect } from "react";

import styles from "./Image.module.scss";

interface IProps extends ImgHTMLAttributes<HTMLImageElement> {
    containerStyle?: React.CSSProperties
}

const Image: React.FC<IProps> = props => {
    const {containerStyle, ...rest} = props

    const [loaded, setLoaded] = useState(false)

    return <div
        className={styles.Container}
        style={containerStyle}
    >
        <img
            {...rest}
            className={`
                ${styles.Image}
                ${loaded ? styles.loaded : ""}
                ${props.className || ""}
            `}
            onLoad={() => {
                setLoaded(true)
            }}
        />
    </div>
}

export default Image;
