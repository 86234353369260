import * as React from 'react'

import RadioButton from '../../../../RadioButton'

import { ICartRadioOption } from '../../../../../types/Order'

interface IProps {
    optionIndex: number
    titles: string[]
    price: (number | string)[]
    currency: string
    selectedOptions?: ICartRadioOption
    onChange: (optionIndex: number, selectedIndex: number) => void
}

const RadioOptions: React.FC<IProps> = props => {
    const [selected, setSelected] = React.useState(props.selectedOptions ? props.selectedOptions.indexes : -1)

    return <>
        {props.titles.map((title, i) => (
            <div
                key={title + "_option_r" + i}
                style={{
                    flexDirection: "row",
                    alignItems: 'center'
                }}
            >
                <RadioButton
                    index={i}
                    label={title}
                    selected={selected!}
                    setSelected={(x) => {
                        setSelected(x);
                        props.onChange(props.optionIndex, i)
                    }}
                    price={props.price[i]}
                    currency={props.currency}
                />
            </div>
        ))}
    </>
}

export default RadioOptions;
