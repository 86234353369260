import React from 'react';

export default function Fail(props) {
    return (
        <div style={{
            display: 'flex',
            flex: 1,
            height: '100%',
            backgroundColor: '#F2F2F2'
        }}>
            
        </div>
    )
}