import { useState } from "react";
import Button from "../Button";
import DefaultModal from "../Modal";
import { NotificationImportantOutlined } from "@material-ui/icons";
import { api, auth } from "../../firebase";
import { useGlobalState } from "../../contexts/GlobalContext";
import { signInAnonymously } from "firebase/auth";

const RequestWaiterModal = ({ active, setActive, onSuccess }) => {
  const { data } = useGlobalState();

  const { table_id, venue_id } = data || {};

  const [loading, setLoading] = useState(false);

  const request = async () => {
    setLoading(true);

    try {
      if (!auth.currentUser?.uid) {
        await signInAnonymously(auth);
      }

      const func = api("venue-table-requestService");

      await func({ v_id: venue_id, t_id: table_id, type: "waiter" }).then(() => {
        setActive(false);
        onSuccess("Request sent!", "The waiter will be with you shortly");
      });
    } catch (e) {
      alert((e as Error)?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <DefaultModal
      active={active}
      setActive={setActive}
      style={{ justifyContent: "normal", minHeight: 0, paddingBottom: 40 }}
    >
      <NotificationImportantOutlined style={{ fontSize: 75, margin: 20 }} />

      <h3>Request Waiter</h3>

      <p style={{ margin: 20, textAlign: "center" }}>
        Need the waiter for something?
        <br />
        Notify them below
      </p>

      <Button loading={loading} onClick={request} style={{ width: "100%" }}>
        Request
      </Button>
    </DefaultModal>
  );
};

export default RequestWaiterModal;
