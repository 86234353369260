import React from "react";

import { useParams, Navigate } from "react-router-dom";
import useDefaultMenuSection from "./hooks/useDefaultMenuSection";

import View from "./View";

const Menu: React.FC = () => {
    const { section } = useParams<{ section: string }>();

    const defaultSection = useDefaultMenuSection()

    if (!section) {
        return <Navigate
            to={defaultSection || '../'}
        />
    }

    return <View />
}

export default Menu;
