import { useEffect, useState } from 'react';
import { NavHashLink } from "react-router-hash-link";
import convertAnchors from "../../../utils/convertAnchor";
import { checkHasItemInStock } from '../../../utils/menu';

const Category = (props: any) => {
  const { catTitle, translatedTitle, activeClassName, hideOutOfStock, itemsarray } = props;

  const [visible, setVisible] = useState(true)

  useEffect(() => {
    if (hideOutOfStock) {
      const hasItemInStock = checkHasItemInStock(itemsarray)
      if (!hasItemInStock) {
        setVisible(false)
      }
    }
  }, [hideOutOfStock, itemsarray])

  if (!visible) {
    return null
  }

  return <NavHashLink
    className={({ isActive }) => isActive ? activeClassName : undefined}
    id={`link_${convertAnchors(catTitle)}`}
    to={`#${convertAnchors(catTitle)}`}
  >
    {translatedTitle ? translatedTitle : catTitle}
  </NavHashLink>

}

export default Category;