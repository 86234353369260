import Modal from "../../Modal";
import LoginForm from "../LoginForm";

interface IProps {
    tryLinkAccount: boolean
    visible: boolean
    setVisible: (visible: boolean) => void
    onLogin: () => void
}

const LoginModal: React.FC<IProps> = props => {
    const { tryLinkAccount, visible, setVisible, onLogin } = props

    return <Modal
        active={visible}
        setActive={setVisible}
        style={{
            padding: 0,
            width: "90%",
            height: "80%"
        }}
    >
        <LoginForm
            tryLinkAccount={tryLinkAccount}
            onLogin={onLogin}
        />
    </Modal>
}

export default LoginModal;
