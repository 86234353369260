import { useEffect, useState, useRef } from 'react';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';

import DefaultModal from '../../../../Components/Modal';

import { Search } from 'react-feather';

import languagesJSON from '../../../../assets/languages.json';

import styles from './index.module.scss'

interface IProps {
    active: boolean
    topLanguages?: string[]
    setActive: React.Dispatch<React.SetStateAction<boolean>>
    translate: (item: typeof languagesJSON.text[0]) => void
}

const LanguageModal: React.FC<IProps> = props => {
    const { active, topLanguages, setActive, translate } = props

    const { height, width } = useWindowDimensions();

    const [languages, setLanguages] = useState<typeof languagesJSON.text>([])

    const inputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
       initLanguages()
    }, [topLanguages])

    const initLanguages = () => {
        if (topLanguages) {
            let temp: typeof languagesJSON.text = []

            languagesJSON.text.forEach(item => {
                if (topLanguages.indexOf(item.code) > -1) {
                    temp = [item, ...temp]
                } else {
                    temp = [...temp, item]
                }
            })

            setLanguages(temp)
        } else {
            setLanguages(languagesJSON.text)
        }
    }

    const handleSearch = (e) => {
        const term = e.target.value.toLowerCase()
        const temp: any = []

        if (term.length >= 2) {
            languagesJSON.text.forEach(item => {
                if (item.language.toLowerCase().includes(term)) {
                    temp.push(item)
                }
            })

            setLanguages(temp)
        } else {
            initLanguages()
        }
    }

    return (<DefaultModal active={active} setActive={setActive} reducePadding>
        <div
            style={{
                width: "100%",
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}
        >
            <span style={{ fontWeight: 'bolder' }}>Translate Menu</span>
            <span>Choose a language</span>
            <label className={styles.SearchLabel + ' ' + styles.Active}>
                <input
                    ref={inputRef}
                    type="text"
                    autoComplete="off"
                    onChange={e => handleSearch(e)}
                    className={styles.SearchInput}
                    disabled={!active}
                />
                <Search className={styles.ImgSearch} />
            </label>

            <div style={{ height: height * 0.5, overflow: 'scroll', width: '100%' }}>
                {languages.map(item => (
                    <div
                        key={item.language + item.code}
                        className={styles.language}
                        onClick={() => {
                            translate(item);
                            setTimeout(() => {
                                inputRef.current!.value = "";
                                initLanguages()
                            }, 400)
                        }}>
                        {item.language}
                    </div>
                ))}
            </div>
        </div>
    </DefaultModal>)
}

export default LanguageModal;
