import React from 'react'

import styles from './BottomSheet.module.scss'

interface IProps {
    active: boolean
    setActive: Function
    disableCloseOnBackClick?: boolean
}

const BottomSheet: React.FC<IProps> = props => {
    const { active, setActive } = props
    return <div className={`${styles.BackContainer} ${active ? styles.active : ''}`}
        onClick={() => !props.disableCloseOnBackClick && setActive(false)}
    >
        <div
            className={`${styles.SheetContainer} ${active ? styles.active : ''}`}
            onClick={e => e.stopPropagation()}
        >
            {props.children}
        </div>
    </div>
}

export default BottomSheet