import React from 'react';
import { Route } from "react-router-dom";
import { Elements } from '@stripe/react-stripe-js';
import { stripePromise } from '../../stripe';
import Stripe from './Stripe';
import Success from './Result/Success';
import Fail from './Result/Fail';

const Payments: React.FC = props => {

    return <Elements stripe={stripePromise}>
        <Route>
            <Route path={`/stripe/:orderID`} element={<Stripe />} />
            <Route path={`/success`} element={<Success />} />
            <Route path={`/fail`} element={<Fail />} />
        </Route>
    </Elements>
};

export default Payments;