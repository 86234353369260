import * as React from 'react'

import { Checkbox, FormControlLabel } from '@material-ui/core'

import { ICartCheckOption } from '../../../../../types/Order'

interface IProps {
    optionIndex: number
    titles: string[]
    price: (number | string)[]
    preselected: boolean[]
    min?: number
    max?: number
    currency: string
    selectedOptions?: ICartCheckOption
    onChange: (optionIndex: number, selectedIndexes: number[]) => void
}

const CheckOptions: React.FC<IProps> = props => {
    const { min, max } = props

    const [selected, setSelected] = React.useState(props.selectedOptions ? props.selectedOptions.indexes! : [])

    const initialSelectedOptions = React.useRef(props.selectedOptions)

    const onSelectChange = (index) => {
        let temp = [...selected]
        let i = selected.indexOf(index)
        if (i === -1) {
            temp.push(index)
        } else {
            temp.splice(i, 1)
        }

        setSelected(temp)
        props.onChange(props.optionIndex, temp)
    }

    const disableUnselectedOptions = max ? (selected.length >= max) : false

    return <>
        {(props.titles.map((option, i) => {
            const isSelected = selected.indexOf(i) > -1

            const isSelectedInitial = initialSelectedOptions.current?.indexes ?
                initialSelectedOptions.current.indexes.indexOf(i) > -1 : undefined

            let initialValue = false

            if (props.preselected) {
                initialValue = props.preselected[i]
            } else if (isSelectedInitial && !props.preselected[i]) {
                initialValue = true
            } else if (!isSelectedInitial && props.preselected[i]) {
                initialValue = true
            }

            return (<div
                key={option + "_option_ch" + i}
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: 'center',
                    marginTop: 5,
                    marginBottom: 5
                }}
            >
                <FormControlLabel
                    label={option}
                    style={{
                        marginRight: 5
                    }}
                    disabled={!isSelected && disableUnselectedOptions}
                    control={
                        <Checkbox
                            style={{
                                color: "#2BBF00",
                            }}
                            checked={isSelectedInitial}
                            onChange={() => { onSelectChange(i); }}
                        />
                    }
                />

                <span
                    className="muted">
                    {props.price[i] && props.price[i] !== "" ? " " + props.price[i] + props.currency : null}
                </span>
            </div>)
        }))}
    </>
}

export default CheckOptions;
