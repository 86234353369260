import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import LoginForm from '../../Components/auth/LoginForm';

const LoginScreen: React.FC = props => {
    const navigate = useNavigate()
    const location = useLocation()

    return <LoginForm
        tryLinkAccount={true}
        onLogin={() => {
            const routeParams = location?.state as { from?: string }

            if (routeParams?.from) {
                navigate(routeParams.from)
            }
        }}
    />
}

export default LoginScreen;
