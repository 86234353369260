import React from 'react';

import styles from './SelectableItem.module.scss';

import { IItem } from '../'

const SelectableItem: React.FC<IItem & {currency: string}> = props => {

    const {currency, title, fullPrice} = props

    return <div className={styles.Container}>
        {/* <div className={`${styles.Checkbox} ${props.selected ? styles.selected : ''}`}>
            {props.selected && <Check size={20} strokeWidth={4} />}
        </div> */}

        <span className={styles.Title}>{title}</span>

        <span className={styles.Price}>{currency}{fullPrice}</span>
    </div>
}

export default SelectableItem