import React from 'react'

import styles from './PercentageBox.module.scss'

interface IProps {
    label: string
    value: number
    onClick: (value:number) => void
    style?: React.CSSProperties
}

const PercentageBox: React.FC<IProps> = props => {
    const {label, value, style, onClick} = props

    return <div key={'p' + value} className={styles.Box} onClick={() => onClick(value)} style={style}>
        <span style={{ fontSize: 14, marginBottom: 4 }}>{label}</span>
        <span style={{ fontSize: 20 }}>{value * 100}%</span>
    </div>
}

export default PercentageBox