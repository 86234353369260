import { IconButton, IconButtonProps } from "@material-ui/core"

interface IProps extends IconButtonProps {
    label: string
    showLabel: boolean
}

const AllergenIcon: React.FC<IProps> = props => {

    return <IconButton
        style={{
            padding: 5,
        }}
        onClick={props.onClick}
    >
        <div>
            {props.children}

            {props.showLabel &&
                <div
                    style={{
                        fontSize: 12,
                        display: "block"
                    }}
                >
                    {props.label.toUpperCase()}
                </div>
            }
        </div>
    </IconButton>
}

export default AllergenIcon;
