import { IItem, Menu } from "../types/Menu";
import { Cart, ICartItem } from "../types/Order";

export const getCartTotal = (cart: Cart, menu: Menu) => {
    let total = 0

    for (const item of cart) {
        const itemRef = getMenuItemByID(menu, item.section, item.id)

        if (itemRef) {
            total += getCartItemPrice(item, itemRef)
        }
    }

    return total
}

export const getCartItemPrice = (item: ICartItem, data: IItem) => {
    let itemPrice = Number(data.price);

    const allItemOptions = data.options;

    if (item.options) {
        for (let i = 0; i < item.options.length; i++) {
            let option_price = 0;
            const indexes = item.options[i].indexes

            if (Array.isArray(indexes)) {
                for (let j = 0; j < indexes.length; j++) {
                    const price_index = indexes[j];

                    option_price = Number(allItemOptions[i].price[price_index])

                    if (!isNaN(option_price)) {
                        itemPrice = itemPrice + Number(option_price)
                    }
                }
            } else if ((indexes || indexes === 0) && indexes !== -1) {
                const j = indexes;
                option_price = Number(allItemOptions[i].price[j])


                if (!isNaN(option_price)) {
                    itemPrice = itemPrice + Number(option_price)
                }
            }
        }
    }

    return Number(itemPrice) * item.quantity
}

export const getMenuItemByID = (menu: Menu, section: string, id: string): IItem | null => {
    const menuSection = menu[section]

    for (let category of menuSection) {
        for (let item of category.itemsarray) {
            if (item.id === id) return item
        }
    }

    return null
}

export const checkHasItemInStock = (items: IItem[]) => {
    let hasItemInStock = false
    for (const item of items) {
        if (!item.outOfStock) {
            hasItemInStock = true
            break
        }
    }

    return hasItemInStock
}

// This function takes the 'times' parameter from the category and checks if items can be ordered from it. 
// Returns true if available, false if not
export const checkCategoryTimes = (times: string, date: Date): boolean => {
    let can_order = true

    if (times !== "00:00 - 00:00") {
        let timesArr = times.split(" - ")
        const hhmm0 = timesArr[0].split(":")
        const hhmm1 = timesArr[1].split(":")

        const start = Number(hhmm0[0]) * 60 + Number(hhmm0[1])
        const end = Number(hhmm1[0]) * 60 + Number(hhmm1[1])

        const now = date.getHours() * 60 + date.getMinutes()

        if (start >= now || end <= now) {
            can_order = false;
        }
    }

    return can_order
}
