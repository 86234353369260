import { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie'

import { onValue, off } from 'firebase/database';
import { getChildRef, setChild } from '../../../firebase';

import lottie from 'lottie-web';

import FullscreenLoading from '../../../Components/FullScreenLoading';
import Button from '../../../Components/Button';
import Box from '../../../Components/Box';

import CryIcon from '../../../assets/icons/cry.svg';

import styles from './OrderStatus.module.scss';

export interface IStatus {
    step: number
    orderID: string
    message?: string
}

const progressSteps = [
    { title: "Confirming your order...", },
    { title: "Preparing your order" },
    { title: "Order Ready!" },
]

const ANIMATIONS = [
    require('../../../assets/animations/status/step1.json'),
    require('../../../assets/animations/status/step2.json'),
    require('../../../assets/animations/status/step3.json')
]

function OrderStatus(props) {
    const { orderID } = useParams<{ code: string, orderID: string }>()
    const navigate = useNavigate()
    const [cookies, setCookies] = useCookies();

    const [status, setStatus] = useState<IStatus>()
    const statusRef = useRef<IStatus>()

    const [error, setError] = useState("")

    useEffect(() => {
        setCookies("cart", "")

        onValue(
            getChildRef("orders/status/" + orderID),
            (snapshot) => {
                if (snapshot.val() && orderID) {
                    let activeOrders: string[] = cookies.activeOrders

                    if (activeOrders && !Array.isArray(activeOrders)) {
                        activeOrders = JSON.parse(activeOrders)
                    } else if (!activeOrders) {
                        activeOrders = []
                    }

                    if (!activeOrders.includes(orderID)) {
                        setCookies("cart", "")
                    }

                    activeOrders.push(orderID)

                    setCookies("activeOrders", Array.from(new Set(activeOrders)))

                    setStatus(snapshot.val())
                    statusRef.current = snapshot.val()
                } else {
                    setError("Order not found")
                }
            })

        return () => {
            off(getChildRef("orders/status/" + orderID))
        }
    }, [])

    useEffect(() => {
        const lottieViewElement = document.getElementById("lottie-view")

        if (lottieViewElement) {
            lottieViewElement.innerHTML = ""

            if (status && status.step >= 1) {
                lottie.loadAnimation({
                    container: lottieViewElement!, // the dom element that will contain the animation
                    renderer: 'svg',
                    loop: true,
                    autoplay: true,
                    animationData: ANIMATIONS[status.step - 1]
                });
            }
        }
    }, [status])

    if (error || status?.step === -1) {
        <div style={{ flex: 1 }}>
            <Box style={{ alignSelf: 'center' }}>
                <img
                    src={CryIcon}
                    alt='cry face'
                    style={{ paddingTop: 10, paddingBottom: 10 }}
                />

                <h3>Oops!</h3>
                <p style={{ fontSize: 14 }}>{error}</p>

                <Button
                    style={{ width: '100%', marginTop: 15 }}
                    onClick={() => {
                        off(getChildRef("orders/status/" + orderID))
                        setChild("orders/status/" + orderID, null)
                            .then(() => {
                                // props.navigation.dispatch(
                                //     CommonActions.reset({
                                //         index: 0,
                                //         routes: [
                                //             {
                                //                 name: 'Home',
                                //             },
                                //         ],
                                //     })
                                // )
                            })
                    }}
                >
                    Try Again
                </Button>
            </Box>
        </div >
    }

    return !status ? <FullscreenLoading /> : (
        <div style={{ display: "flex", flexDirection: "column", flex: 1, backgroundColor: "#fff" }}>
            <div style={{ width: '90%', alignSelf: 'center' }}>
                <div style={{ display: "flex", alignItems: 'center', paddingTop: 20 }}>
                    <h4>#{status.orderID}</h4>
                </div>
                <div style={{ marginTop: 5, paddingBottom: 15 }}>
                    <h2 style={{ textAlign: "center" }}>{progressSteps[status.step - 1].title}</h2>
                    <div style={{ display: "flex", marginTop: 15 }}>
                        <div
                            className={`${styles.ProgressBar} ${status.step >= 1 ? styles.active : ""}`}
                        />
                        <div
                            className={`${styles.ProgressBar} ${status.step >= 2 ? styles.active : ""}`}
                        />
                        <div
                            className={`${styles.ProgressBar} ${status.step >= 3 ? styles.active : ""}`}
                        />
                    </div>
                </div>
                {status.message ? <div style={{ textAlign: "center" }}>
                    <div className='muted'>{status.message}</div>
                </div> : null}
                {status.step === 3 ? <h4 style={{ textAlign: "center" }}>Ticket Number: #{status.orderID}</h4> : null}
            </div>

            <div
                id="lottie-view"
                style={{ width: "100%", height: "100%" }}
            />
            {/* <LottieView
                        ref={animationRef}
                        resizeMode={Platform.OS === "android" ? "cover" : undefined}
                        source={ANIMATIONS[status.step - 1]}
                        loop
                        autoPlay
                        onLayout={() => {
                            animationRef.current?.play()
                        }}
                    /> */}
        </div>
    )
};

export default OrderStatus;
