import React from "react";

import styles from "./Button.module.scss";

import { CircularProgress } from "@material-ui/core";

interface IProps {
  id?: string;
  type?: string;
  onClick: (e: any) => void;
  style?: React.CSSProperties;
  color?: 1 | 2 | 3 | string;
  fullWidth?: boolean;
  disabled?: boolean;
  loading?: boolean;
}

const Button: React.FC<IProps> = (props) => {
  const { color, fullWidth } = props;

  const disabled = props.disabled || props.loading;

  const style = {
    backgroundColor:
      typeof color === "string"
        ? color
        : !color || color === 1
        ? undefined
        : color === 2
        ? "#2BBF00"
        : "#0800FF",
    width: fullWidth ? "90%" : undefined,
    ...props.style,
  };

  return (
    <button
      id={props.id}
      className={`${styles.Button} ${disabled ? styles.disabled : ""}`}
      style={style}
      onClick={!disabled ? props.onClick : undefined}
    >
      <div className={styles.InnerContainer}>
        {props.loading ? (
          <CircularProgress style={{ height: 20, width: 20, color: "white" }} />
        ) : (
          props.children
        )}
      </div>
    </button>
  );
};

export default Button;
