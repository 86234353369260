import React, { useEffect, useRef, useState } from 'react';
import styles from "../Menu.module.scss";
import { useLocation } from 'react-router-dom'
import convertAnchors from "../../../utils/convertAnchor";
import { checkCategoryTimes, checkHasItemInStock } from '../../../utils/menu';
import Item from '../Item';

const date = new Date()

const Position: React.FC<any> = props => {
    const {
        index,
        catTitle,
        translatedTitle,
        itemsarray,
        currency,
        onChange,
        searchKey,
        hideOutOfStock,
        days,
        addScrollListener,
        removeScrollListener
    } = props;

    const ref = useRef<HTMLDivElement>(null);
    const { hash } = useLocation();

    const [visible, setVisible] = useState(true)

    let times = props.times

    let can_order = true

    if (times) {
       can_order = checkCategoryTimes(times, date)
    }

    let displayDays = ""
    let show_days = false
    let all_days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
    if (days) {
        let td_index = date.getDay()
        if (!days[td_index]) {
            can_order = false
        }

        for (let j = 0; j < days.length; j++) {
            if (days[j]) {
                displayDays = displayDays + all_days[j] + ", "
            } else {
                show_days = true
            }
        }
        displayDays = displayDays.slice(0, -2)
    }

    useEffect(() => {
        if (hideOutOfStock) {
            const hasItemInStock = checkHasItemInStock(itemsarray)

            if (!hasItemInStock) {
                setVisible(false)
            }
        }
    }, [hideOutOfStock, itemsarray])

    useEffect(() => {
        if (hash.substring(1) === convertAnchors(catTitle)) {
            ref.current?.scrollIntoView();
        }
    }, [hash, ref]);

    useEffect(() => {
        if (ref.current != null) {
            addScrollListener(onScroll)
        }

        function onScroll(event) {
            if (!ref.current) {
                return;
            }

            const rect = ref.current!.getBoundingClientRect();
            if (rect.y < 200 && rect.y > 200 - rect.height) {
                onChange(`link_${convertAnchors(catTitle)}`);
            }
        }

        return () => removeScrollListener(onScroll);
    }, [ref]);

    if (!visible) {
        return null
    }

    return (
        <div id={convertAnchors(catTitle)} className={styles.Position} ref={ref}>
            <h2 className={styles.TitleSection}>
                {translatedTitle ? translatedTitle : catTitle}
            </h2>

            <div className="muted">
                {times !== "00:00 - 00:00" ? times : show_days ? "All day" : null} {show_days ? "on " + displayDays : null}
            </div>

            <br />

            {itemsarray
                .filter(item => {
                    const reg = new RegExp(escapeRegExp(searchKey), "ig")

                    return reg.test(item.translatedTitle || item.title)
                        || reg.test(item.translatedDesc || item.desc)
                }).map((item, index) =>
                    <Item
                        key={`item${item.title}${index}`}
                        catIndex={index}
                        index={index}
                        currency={currency}
                        item={item}
                        hideOutOfStock={hideOutOfStock}
                        canOrder={can_order}
                        onClick={props.onClickItem}
                    />
                )}
        </div>
    )
}

function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export default Position;