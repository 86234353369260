import React from "react";
import "./App.css";
import GlobalContextWrapper from "./contexts/GlobalContext";
import Routes from "./Routes";

const App: React.FC = (props: any) => {
  return (
    <GlobalContextWrapper>
      <Routes />
    </GlobalContextWrapper>
  );
};

export default App;
