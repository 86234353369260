import { ChevronRight } from 'react-feather'
import styles from './SectionCard.module.scss'

interface IProps {
    name: string
    val?: string
    imageURL?: string
    onSelect: (val: string) => void
}

const SectionCard: React.FC<IProps> = props => {
    const { name, val, imageURL } = props

    return <div
        className={styles.Container}
        onClick={() => {
            props.onSelect(val || name)
        }}
    >
        <img
            src={imageURL}
            className={styles.Image}
        />

        <div className={styles.SectionNameContainer}>
            {name}

            <ChevronRight
                style={{
                    position: 'absolute',
                    right: 5,
                }}
            />
        </div>
    </div>
}

export default SectionCard