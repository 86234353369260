import React from "react";
import styles from './index.module.scss'
import { CircularProgress } from "@material-ui/core";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const FullscreenLoading: React.FC = props => {

    const { width, height } = useWindowDimensions();

    return <div className={styles.Main}>
        <div className={styles.Circle} style={{width: width * 0.45, height: width * 0.45, borderRadius: width * 0.45 / 2}}>
            <CircularProgress color="inherit" />
            <span style={{color: 'black', marginTop: 15, fontSize: 'smaller'}}>One sec...</span>
        </div>
    </div>
}

export default FullscreenLoading;