import { useState } from "react";
import { useGlobalState } from "../../contexts/GlobalContext";
import { getCartItemPrice, getMenuItemByID } from "../../utils/menu";
import ItemModal from "../Menu/ItemModal";

import styles from "./CartDetails.module.scss";

import { IItem, Menu } from "../../types/Menu";
import { Cart, ICartItem } from "../../types/Order";
import { IconButton } from "@material-ui/core";
import { Edit2, Trash } from "react-feather";

interface IProps {
  cart: Cart;
  menu: Menu;
  currency: string;
  disabled?: boolean;
}

interface ISelectedOptionsProps {
  item: ICartItem;
  data: IItem;
  currency: string;
}

const CartDetails: React.FC<IProps> = (props) => {
  const { cart, menu, currency, disabled } = props;

  const { updateCart } = useGlobalState();

  const [selectedItem, setSelectedItem] = useState<IItem>();
  const [selectedItemCartIndex, setSelectedItemCartIndex] = useState(0);

  if (!cart || !cart.length) {
    return null;
  }

  return (
    <>
      {cart.map((cartItem, i) => {
        const itemRef = getMenuItemByID(menu, cartItem.section, cartItem.id);

        if (!itemRef) {
          return null;
        }

        const q = cartItem.quantity;
        const itemPrice = Number(itemRef.price);

        // checking if there is a comment
        let comment = cartItem.comments;

        if (comment) {
          comment = "Comments: " + comment;
        } else {
          comment = "";
        }

        return (
          <div key={`item_${i}_${cartItem.id}`} className={styles.Container}>
            <div style={{ paddingTop: 5 }}>{q} x</div>
            <div style={{ flex: 1, paddingTop: 5 }}>
              <div>
                {itemRef.title}
                {itemPrice !== 0 ? " (" + itemPrice + currency + ")" : null}
              </div>
              <SelectedOptions
                item={cartItem}
                data={itemRef}
                currency={currency}
              />
              {comment ? <div className="muted">{comment}</div> : null}
            </div>
            <div style={{ display: "flex", alignItems: "flex-start" }}>
              <div style={{ paddingTop: 5, paddingRight: 4 }}>
                {currency}
                {getCartItemPrice(cartItem, itemRef).toFixed(2)}
              </div>

              <IconButton
                style={{
                  padding: 4,
                  marginRight: 5,
                }}
                onClick={() => {
                  setSelectedItem(itemRef);
                  setSelectedItemCartIndex(i);
                }}
                disabled={disabled}
              >
                <Edit2 />
              </IconButton>

              <IconButton
                style={{
                  padding: 4,
                }}
                onClick={() => {
                  updateCart("DELETE", i);
                }}
                disabled={disabled}
              >
                <Trash color="red" />
              </IconButton>
            </div>
          </div>
        );
      })}

      {selectedItem ? (
        <ItemModal
          type="edit"
          item={selectedItem}
          initialData={{
            options: cart[selectedItemCartIndex].options,
            comments: cart[selectedItemCartIndex].comments,
            quantity: cart[selectedItemCartIndex].quantity,
            priority: cart[selectedItemCartIndex].priority,
          }}
          section={cart[selectedItemCartIndex].section}
          currency={currency}
          addToCart={(cartItem) => {
            updateCart("SET_ITEM", {
              index: selectedItemCartIndex,
              item: { ...cartItem },
            });

            setSelectedItem(undefined);
          }}
          onClose={() => {
            setSelectedItem(undefined);
            setSelectedItemCartIndex(0);
          }}
        />
      ) : null}
    </>
  );
};

const SelectedOptions: React.FC<ISelectedOptionsProps> = (props) => {
  const allItemOptions = props.data.options;

  const selectedOptions = props.item.options;

  return (
    <>
      {selectedOptions.map((selectedOption, index) => {
        const title = selectedOption.title;
        if (Array.isArray(selectedOption.indexes)) {
          return selectedOption.indexes.map((option, i) => {
            let total = Number(allItemOptions[index].price[option]) || 0;
            return (
              <div key={"options_ch" + i + "_" + props.item.id + "_" + index}>
                <div className="muted">
                  {title}: {allItemOptions[index].preselArray[i] ? "-" : "+"}{" "}
                  {allItemOptions[index].options[option]}
                  {total ? " + " + total + props.currency : null}
                </div>
              </div>
            );
          });
        } else {
          if (
            (selectedOption.indexes || selectedOption.indexes === 0) &&
            selectedOption.indexes !== -1
          ) {
            let total =
              Number(allItemOptions[index].price[selectedOption.indexes]) || 0;
            return (
              <div key={"option_r" + props.item.id + "_" + index}>
                <div className="muted">
                  {allItemOptions[index].title}:{" "}
                  {allItemOptions[index].options[selectedOption.indexes]}
                  {total ? " + " + total + props.currency : null}
                </div>
              </div>
            );
          }
        }

        return null;
      })}
    </>
  );
};

export default CartDetails;
