type ItemID = string
type Price = number
type DietPreferences = "none" | "vegan" | "vegetarian"
type Tags = "new" | "popular" | "spicy" | "alcohol" | "signature" | "chefs-choice"

export interface Menu {
    [section: string]: ICategory[]
}

export interface ICategory {
    catTitle: string
    imageURL?: string
    days: boolean[] // LENGTH=7, SUN-SAT
    times: string
    itemsarray: IItem[]
    translatedTitle?: string
}

export interface IItem {
    id: string
    title: string
    desc: string
    translatedTitle?: string
    translatedDesc?: string
    imageURL: string
    price: Price
    unit?: string
    itemID: ItemID
    outOfStock: boolean
    disabled?: boolean
    contains: string[]
    maycontain: string[]
    type: DietPreferences[]
    tags: Tags[]
    options: (IRadioOption | ICheckOption)[]
    showPriority: boolean
}

export interface IOption {
    id?: string
    title: string
    type: "radio" | "check"
    options: string[] // options array: each of the option corresponds to the data below with the same index
    itemIDArray: ItemID[]
    price: (Price | "")[] // price array
    preselArray: boolean[]
    min?: number
    max?: number
}

interface IRadioOption extends IOption {
    type: "radio"
    required: boolean
}

interface ICheckOption extends IOption {
    type: "check"
}

export function isRadioOption(options: IRadioOption | ICheckOption): options is IRadioOption {
    return (<IRadioOption>options).type === "radio";
}
