import React from "react";
import { useParams } from "react-router";
import { useCookies } from "react-cookie";

import GIFLinks from "../../assets/misc/GIFLinks";
import Button from "../../Components/Button";
import FullscreenLoading from "../../Components/FullScreenLoading";

import { ExternalLink } from "react-feather";

import {
  onAuthStateChanged,
  getRedirectResult,
  getAdditionalUserInfo,
} from "firebase/auth";
import { auth } from "../../firebase";
import { handleFacebookError } from "../../utils/auth";
import { register } from "../../api";

import styles from "./PaySuccess.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useGlobalState } from "../../contexts/GlobalContext";

const PaySuccessScreen: React.FC = (props) => {
  const { updateCart } = useGlobalState();
  const { action } = useParams<{ action: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const [cookies, setCookies] = useCookies();
  const [loading, setLoading] = React.useState(false);
  const [gif, setGif] = React.useState<number | null>(null);
  const [gifLoaded, setGifLoaded] = React.useState(false);

  const [receiptID, setReceiptID] = React.useState("");

  const [isAnonymous, setIsAnonymous] = React.useState(false);

  React.useEffect(() => {
    const { search } = location;

    if (search) {
      const params = new URLSearchParams(search);

      const gifNumStr = params.get("gif") || "0";
      const receiptID = params.get("receiptID") || "";

      let gifNum = parseFloat(gifNumStr);

      if (gifNum > GIFLinks.length - 1) {
        gifNum = 0;
      }

      setCookies("cart", "");
      updateCart("CLEAR", {});

      setGif(gifNum);
      setReceiptID(receiptID);
    } else {
      setGif(0);
    }

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user || user?.isAnonymous) {
        setIsAnonymous(true);
      } else {
        setIsAnonymous(false);
      }
    });

    getRedirectResult(auth)
      .then(async (result) => {
        if (result && result.user) {
          const details = getAdditionalUserInfo(result);
          if (details?.isNewUser) {
            setLoading(true);
            const { first_name, last_name, email, id } = details.profile as any;

            if (first_name || email) {
              const data = {
                uid: auth.currentUser?.uid,
                email: email,
                un:
                  first_name.toLowerCase().replace(/\s/g, "") +
                  "_" +
                  last_name.toLowerCase().replace(/\s/g, ""),
                fn: first_name,
                ln: last_name,
                os: "web",
                other_private: {
                  facebookID: id,
                },
              };

              await register(data).catch((err) => {
                console.log(err);
              });

              setLoading(false);
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
        handleFacebookError(err);
      });

    return () => unsubscribe();
  }, []);

  return (
    <div className={styles.Container}>
      <h2>Payment successful :)</h2>
      <span>
        {action === "pay"
          ? "It was great having you!"
          : "Your order has been sent to the venue."}
        {isAnonymous
          ? " Create an account to save your information for next time"
          : ""}
      </span>

      {isAnonymous ? (
        <>
          {/* <Button color='#3B5998' onClick={facebookSignIn} style={{ marginTop: 15 }}>
                <Facebook style={{ marginRight: 10 }} />
                Create Account with Facebook
            </Button> */}

          {/* <Button color='#000' onClick={() => { }}>
            <Apple style={{ marginRight: 10 }} />
            Sign in with Apple
        </Button> */}

          <Button
            color="transparent"
            style={{ color: "#414141", fontSize: 14 }}
            onClick={() =>
              navigate("/login", { state: { from: window.location.pathname } })
            }
          >
            Create Account with Phone Number
          </Button>
        </>
      ) : (
        <div style={{ marginBottom: 10 }}>Thank you for using Sally</div>
      )}

      <div className={styles.GIFContainer}>
        {(gif || gif === 0) && (
          <img
            src={GIFLinks[gif]}
            className={styles.GIF}
            style={{
              opacity: gifLoaded ? 1 : undefined,
            }}
            alt="happy success gif"
            onLoad={() => {
              setGifLoaded(true);
            }}
          />
        )}
      </div>

      <div className={styles.BottomContainer}>
        <h3>{action === "pay" ? "You're free to go!" : ""}</h3>
        <p>
          {action === "pay"
            ? "The waiter already knows you paid. Feel free to go and don't hesitate to say thank you!"
            : ""}
        </p>
        {receiptID && (
          <a
            href={`https://sally.app/receipt.php?id=${receiptID}`}
            target="_blank"
            rel="noreferrer"
          >
            <Button
              color="transparent"
              onClick={() => {}}
              style={{ color: "#414141", width: "100%" }}
            >
              <ExternalLink size={20} style={{ marginRight: 10 }} />
              RECEIPT
            </Button>
          </a>
        )}
      </div>

      {loading && <FullscreenLoading />}
    </div>
  );
};

export default PaySuccessScreen;
