import { CreditCard, CheckCircle } from "react-feather"

import styles from "./PaymentCard.module.scss"

import ICard from "../../types/Card"

interface IProps {
    data: ICard
    selected: boolean
    onClick: () => void
}

const PaymentCard: React.FC<IProps> = props => {

    return <div
        className={`${styles.PaymentMethod} touchable ${props.selected ? styles.selected : ''}`}
        onClick={props.onClick}
    >
        <CreditCard style={{ marginRight: 16 }} />
        <div style={{ flex: 1 }}>
            •••• {props.data.cardNo}
            <div style={{ fontSize: 12, fontWeight: 'normal', marginTop: 0 }}>
                {props.data.expMonth}/{props.data.expYear}
            </div>
        </div>

        {props.selected && <CheckCircle color='#2BBF00' />}
    </div>
}

export default PaymentCard;
