import { useState } from "react";
import Button from "../Button";
import DefaultModal from "../Modal";
import { ReceiptOutlined } from "@material-ui/icons";
import { api, auth } from "../../firebase";
import { useGlobalState } from "../../contexts/GlobalContext";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { signInAnonymously } from "firebase/auth";

const RequestBillModal = ({ active, setActive, onSuccess }) => {
  const { data } = useGlobalState();

  const { table_id, venue_id } = data || {};

  const [loading, setLoading] = useState(false);

  const [paymentMethod, setPaymentMethod] = useState("card");

  const request = async () => {
    setLoading(true);

    try {
      if (!auth.currentUser?.uid) {
        await signInAnonymously(auth);
      }

      const func = api("venue-table-requestService");

      await func({ t_id: table_id, v_id: venue_id, type: "bill", paymentMethod }).then(
        (data) => {
          setActive(false);
          onSuccess("Request sent!", "We have notified the venue");
        }
      );
    } catch (e) {
      alert((e as Error)?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <DefaultModal
      active={active}
      setActive={setActive}
      style={{ justifyContent: "normal", minHeight: 0, paddingBottom: 40 }}
    >
      <ReceiptOutlined style={{ fontSize: 75, margin: 20 }} />

      <h3>Request Bill</h3>

      <p style={{ margin: 20, textAlign: "center" }}>Ready to pay the bill?</p>

      <RadioGroup
        style={{
          marginBottom: 25,
        }}
        aria-labelledby="payment-method-type"
        name="payment-method-type"
        defaultValue="card"
        onChange={(e) => {
          setPaymentMethod(e.target.value);
        }}
      >
        <FormControlLabel
          value="card"
          control={<Radio style={{ color: "#2BBF00" }} />}
          label="Pay by card"
        />

        <FormControlLabel
          value="cash"
          control={<Radio style={{ color: "#2BBF00" }} />}
          label="Pay by cash"
        />
      </RadioGroup>

      <Button loading={loading} onClick={request} style={{ width: "100%" }}>
        Request
      </Button>
    </DefaultModal>
  );
};

export default RequestBillModal;
