import React from 'react';
import { Close } from '@material-ui/icons';
import Image from '../../../Components/Image';
import Tags from '../../../Components/Menu/Tags';
import styles from '../Menu.module.scss';
import Allergens from '../../../Components/Menu/Allergens';
import DietPreferences from '../../../Components/Menu/Allergens/DietPreferences';

export default function Item(props) {
    const { index, item, currency, hideOutOfStock, canOrder, onClick } = props

    const { title, translatedTitle, desc, translatedDesc, contains, maycontain,
        options, tags, type, price, unit, imageURL, outOfStock, disabled } = item;

    const [focused, setFocused] = React.useState(false);

    if (outOfStock && hideOutOfStock) {
        return null
    }

    return (
        <div
            className={styles.PositionCard}
            onClick={() => {
                if (onClick && !outOfStock && !disabled && canOrder) {
                    onClick(item)
                } else {
                    setFocused(!focused)
                }

                if (!canOrder && !focused) {
                    alert("You're out of luck 😕 This item is not available at this time")
                }
            }}
        >
            <div className={styles.PositionContainer}>
                <div className={`${styles.ItemInfoContainer} ${outOfStock ? styles.OutOfStock : ''}`}>
                    {(focused && imageURL) && <div>
                        <Close className={styles.FocusedCloseIcon} />
                        <img src={imageURL} className={styles.ItemImgFocused} alt="img-focused" />
                    </div>}

                    <h3 className={styles.ItemTitle}>{translatedTitle ? translatedTitle : title}</h3>

                    {tags && tags.length > 0 ? <Tags tags={tags} itemIndex={index} /> : null}

                    <div className={styles.ItemDescContainer}>
                        <div className={`${styles.ItemDesc} ${focused ? styles.focused : ''}`} >
                            {translatedDesc ? translatedDesc : desc}
                        </div>
                    </div>

                    {(disabled && !outOfStock && canOrder) &&
                        <p style={{ fontWeight: "bold" }}>Please ask the waiter to order this</p>
                    }

                    <DietPreferences
                        index={index}
                        types={type || []}
                        iconStyle={{
                            height: 24,
                            width: 24,
                        }}
                    />

                    {focused && <Allergens
                        index={index}
                        contains={contains || []}
                        mayContain={maycontain || []}
                        iconStyle={{
                            height: 24,
                            width: 24,
                        }}
                    />}

                    {focused && <div>
                        {options && options.map((option, optionIndex) => {
                            if (option.options?.length > 0) {
                                return <div className={styles.ItemOptionsGroup} key={`item${index}_optiongroup${optionIndex}`}>
                                    <div className={styles.ItemOptionTitle}>{option.title}</div>
                                    {option.options.map((suboption, suboptionIndex) => {
                                        const price = option.price[suboptionIndex]

                                        return <div key={`item${index}_optiongroup${optionIndex}_suboption${suboptionIndex}`}>
                                            - {suboption}
                                            <span className={styles.ItemOptionPrice}>{price && ` ${currency}${price}`}</span>
                                        </div>
                                    })}
                                </div>
                            }

                            return null
                        })}
                    </div>}
                </div>

                {(imageURL && !focused) &&
                    <Image
                        src={imageURL}
                        containerStyle={{
                            flex: 1,
                            marginLeft: 2,
                            height: "90px",
                            maxWidth: 250,
                            borderRadius: 10
                        }}
                        style={{
                            borderRadius: 10
                        }}
                    />
                }
            </div>

            {Number(price) > 0 ?
                <span
                    className={styles.ItemPrice}
                    style={{ color: outOfStock && "gray" }}>
                    {currency} {Number(price).toFixed(2)} {unit && (" / " + unit)}
                </span>
                : null}

            {outOfStock && <div className={styles.ItemOutOfStockLabel}>OUT OF STOCK</div>}
        </div>
    )
}