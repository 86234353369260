import { useNavigate, useParams } from "react-router-dom";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import { stripeCreateIntent } from "../../../api";

const useStripeSubmit = () => {
    const { orderID } = useParams<{ orderID: string }>();
    const navigate = useNavigate();
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');

    const [userID, setUserID] = useState('');
    const [clientSecret, setClientSecret] = useState('');
    const [total, setTotal] = useState(0);
    const [currency, setCurrency] = useState('');
    const [title, setTitle] = useState('');
    const [desc, setDesc] = useState('');

    // rq9TrVfL4Qrktagb0URy

    useEffect(() => {
        const createIntent = async () => {
            setLoading(true);
            try {
                const intent = await stripeCreateIntent({ orderID: orderID })
                    .catch(err => {
                        navigate('/payments/fail')
                        return
                    })

                if (!intent) {
                    return
                }

                const {
                    client_secret,
                    uid,
                    total,
                    currency,
                    success,
                    status,
                    title,
                    desc
                } = intent

                if (success || status === 'requires_capture') {
                    navigate('/payments/success')
                    return
                }

                if (status === 'authentication_required' || status === 'requires_confirmation' || status === 'requires_action') {

                    const result = await stripe!.confirmCardPayment(client_secret)

                    if (result.error) {
                        navigate('/payments/fail')
                    } else {
                        if (result.paymentIntent.status === 'succeeded' ||
                            result.paymentIntent.status === 'requires_capture') {
                            navigate('/payments/success')
                        }
                    }

                    return
                }

                setUserID(uid);
                setClientSecret(client_secret);
                setTotal(Number(total));
                setCurrency(currency);
                setTitle(title);
                setDesc(desc);
            } catch (err) {
                console.log(err)
                const error = err as Error
                setErrorMessage(error.message);
            } finally {
                setLoading(false);
            }
        }

        if (stripe) {
            createIntent();
        }
    }, [stripe]);

    const submitNewCard = async (e: any, inputValueName: any) => {
        e.preventDefault();
        try {
            setLoading(true);
            setErrorMessage('');

            if (!stripe || !elements) {
                return;
            }

            const cardElement = elements.getElement(CardElement);

            const result = await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: cardElement!,
                    billing_details: {
                        name: inputValueName
                    },
                    metadata: {
                        admin: 0, // 0 because its falsy
                        uid: userID
                    }
                }
            })

            if (result.error) {
                setErrorMessage(result.error.message!)
            } else {
                if (result.paymentIntent.status === 'succeeded' ||
                    result.paymentIntent.status === 'requires_capture') {
                    navigate('/payments/success')
                }
            }
        } catch (err) {
            const error = err as Error
            setErrorMessage(error.message);
        } finally {
            setLoading(false);
        }
    }

    return {
        loading: loading,
        errorMessage: errorMessage,
        submitNewCard,
        total,
        currency,
        title,
        desc
    };
}

export default useStripeSubmit;