import React from 'react'
import useWindowDimensions from '../../hooks/useWindowDimensions'

import DefaultModal, { IModalProps } from '../Modal'

import { Search } from 'react-feather';

import dialCodes from '../../assets/misc/dialCodes.json'

import styles from './DialCodePicker.module.scss'

interface IProps {
    visible: boolean
    setVisible: (visible: boolean) => void
    select: (data: { flag: string, dial_code: string }) => void
    modalStyle?: IModalProps["style"]
}

const DialCodePickerModal: React.FC<IProps> = ({ visible, setVisible, select, modalStyle }) => {
    const { width, height } = useWindowDimensions()

    const [codes, setCodes] = React.useState(dialCodes)

    const inputRef = React.useRef<HTMLInputElement>(null)

    const handleSearch = (e) => {
        const term = e.target.value;
        let matchedItemsArray: any = []
        if (term === '') {
            setCodes(dialCodes)
        } else {
            dialCodes.map((item) => {
                item.data.map((c) => {
                    if (c.name.toLowerCase().includes(term.toLowerCase())) {
                        let index = matchedItemsArray.findIndex(x => x.title === item.title)
                        index === -1 ? matchedItemsArray.push({ title: item.title, data: [c] }) : matchedItemsArray[index].data.push(c)
                    }
                })

            })

            setCodes(matchedItemsArray)
        }
    }


    return <DefaultModal
        active={visible}
        setActive={setVisible}
        style={modalStyle}
    >
        <div style={{ width: width * 0.8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <span style={{ fontWeight: 'bolder' }}>Choose Country</span>
            <label className={styles.SearchLabel + ' ' + styles.Active}>
                <input
                    id="country"
                    name="country"
                    ref={inputRef}
                    onChange={handleSearch}
                    className={styles.SearchInput}
                    type="text"
                />
                <Search className={styles.ImgSearch} />
            </label>

            <div style={{ height: height * 0.5, overflow: 'scroll', width: '100%' }}>
                {codes.map(item => (
                    <div
                        key={'s' + item.title}
                    >
                        <span className={styles.ABCLetter}>{item.title}</span>
                        {item.data.map(code => (
                            <div
                                key={code.code + code.dial_code}
                                className={styles.item}
                                onClick={() => {
                                    select({ flag: code.flag, dial_code: code.dial_code })
                                    setTimeout(() => {
                                        inputRef.current!.value = ''
                                        setCodes(dialCodes)
                                    }, 400)
                                }}
                            >
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ flex: 1 }}>
                                        {code.flag}
                                        {code.name}
                                    </div>

                                    <div className={styles.muted}>
                                        {code.dial_code}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    </DefaultModal>
}

export default DialCodePickerModal;
