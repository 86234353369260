import React from 'react'
import { useParams } from 'react-router'
import { useCookies } from 'react-cookie'

import { IconButton } from '@material-ui/core'
import { ArrowLeft } from 'react-feather'

import Logo from '../../assets/logo.svg'

import styles from './Header.module.scss'
import { useNavigate, useLocation } from 'react-router-dom'

interface IProps {
  back?: boolean
}

const Header: React.FC<IProps> = props => {
  const navigate = useNavigate()
  const location = useLocation()
  const { code } = useParams<{ code?: string }>()
  const [cookies] = useCookies();

  const goBack = () => {
    if (location.state?.from) {
      navigate(-1)
    } else if (code) {
      navigate(`/venue/${code}/`)
    } else if (cookies.table_code) {
      navigate(`/venue/${cookies.table_code}/`)
    } else {
      navigate(`/`)
    }
  }

  return <header className={styles.Container}>
    <div className={styles.SubContainer}>
      {props.back && <IconButton onClick={goBack}>
        <ArrowLeft size={28} color='gray' />
      </IconButton>}
    </div>

    <img src={Logo} className={styles.Logo} alt='Sally logo' />

    <div className={styles.SubContainer} style={{ justifyContent: 'flex-end' }}>

    </div>

  </header>
}

export default Header;