import React from 'react';

import { Plus, Minus } from 'react-feather';

import styles from './InputSpinner.module.scss';

interface IProps {
    containerStyle?: React.CSSProperties
    circlesStyle?: React.CSSProperties
    num: number,
    min?: number,
    pre?: string,
    step: number,
    onChange: (num: number) => void
}

const InputSpinner: React.FC<IProps> = props => {
    const { num, min = 0 } = props

    const update = (i: 1 | -1) => {
        let newNum = num

        newNum = newNum + props.step * i

        if (newNum < min) {
            props.onChange(min)
            return;
        }

        props.onChange(newNum)
    }

    return <div style={props.containerStyle} className={styles.Container}>
        <div
            style={props.circlesStyle}
            className={`${styles.Circle} ${styles.Minus}`}
            onClick={() => update(-1)}
        >
            <Minus />
        </div>

        <span className={styles.Num}>{props.pre}{num}</span>

        <div
            style={props.circlesStyle}
            className={`${styles.Circle} ${styles.Plus}`}
            onClick={() => update(1)}
        >
            <Plus />
        </div>
    </div>
}

export default InputSpinner