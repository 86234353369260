import React from 'react'
import styles from "./Modal.module.scss";

import { IconButton } from '@material-ui/core'
import { Close } from '@material-ui/icons'

export interface IModalProps {
    style?: React.CSSProperties
    containerStyle?: React.CSSProperties
    active: boolean
    setActive: Function
    onClose?: () => void
    reducePadding?: boolean
    children?: JSX.Element | JSX.Element[]
    disableCloseOnBackClick?: boolean
    x?: boolean
}

const Modal = ({
    style,
    containerStyle,
    active,
    setActive,
    onClose,
    reducePadding,
    children,
    disableCloseOnBackClick,
    x,
}: IModalProps) => {
    const innerContainerStyle: React.CSSProperties = {
        paddingLeft: reducePadding ? 10 : undefined,
        paddingRight: reducePadding ? 10 : undefined,
        ...style
    }

    return (
        <div
            className={active ? styles.ModalBackground + ' ' + styles.Active : styles.ModalBackground}
            style={containerStyle}
            onClick={() => {
                if (!disableCloseOnBackClick) {
                    setActive(false)

                    if (onClose) {
                        onClose()
                    }
                }
            }}
        >
            <div
                className={active ? styles.Modal + ' ' + styles.Active : styles.Modal}
                style={innerContainerStyle}
                onClick={e => e.stopPropagation()}
            >
                {x && <IconButton style={{ position: 'absolute', top: 4, right: 6 }} onClick={() => setActive(false)}>
                    <Close />
                </IconButton>}
                {children}
            </div>
        </div>
    )
}

export default Modal;
